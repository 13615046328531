import React from 'react';

const CandidateMarkReport = ({ candidateReportDetails }) => {
  let totalTimeAllocated = 0;
  let totalTimeLeft = 0;

  if (candidateReportDetails.timeDetails.skillsAnalysis?.allocatedTime) {
    totalTimeAllocated +=
      candidateReportDetails.timeDetails.skillsAnalysis.allocatedTime;
    totalTimeLeft +=
      candidateReportDetails.timeDetails.skillsAnalysis.timeLeft || 0;
  }

  if (candidateReportDetails.timeDetails.psychometricAnalysis?.allocatedTime) {
    totalTimeAllocated +=
      candidateReportDetails.timeDetails.psychometricAnalysis.allocatedTime;
    totalTimeLeft +=
      candidateReportDetails.timeDetails.psychometricAnalysis.timeLeft || 0;
  }
  const timeTaken = totalTimeAllocated - Math.round(totalTimeLeft / 60);

  return (
    <div className="cand_result_det_container">
      <div className="cand_result_det">
        <div className="score_sec">
          <div className="score_title">Marks Scored</div>
          <div className="cand_rep_box ">
            {candidateReportDetails.timeDetails.skillsAnalysis ? (
              <>
                <div className="cand_rep_mark_strong">
                  {candidateReportDetails.testScore}
                </div>
                <div className="cand_rep_mark_normal">
                  <span>of {candidateReportDetails.totalMarks}</span>
                </div>
              </>
            ) : (
              <div className="cand_rep_mark_strong">N/A</div>
            )}
          </div>
        </div>
        <div className="score_sec">
          <div className="score_title">Time Taken</div>
          <div className="cand_rep_box ">
            <div className="cand_rep_mark_strong">{timeTaken}</div>
            <div className="cand_rep_mark_normal">
              <span>of {totalTimeAllocated} mins</span>
            </div>
          </div>
        </div>
        <div className="score_sec">
          <div className="score_title">Correct Answers</div>
          <div className="cand_rep_box ">
            {candidateReportDetails.timeDetails.skillsAnalysis ? (
              <>
                <div className="cand_rep_mark_strong">
                  {candidateReportDetails.numberOfCorrectAnswers}
                </div>
                <div className="cand_rep_mark_normal">
                  <span>of {candidateReportDetails.totalCorrectAnswers}</span>
                </div>
              </>
            ) : (
              <div className="cand_rep_mark_strong">N/A</div>
            )}
          </div>
        </div>
      </div>
      {!candidateReportDetails.timeDetails.skillsAnalysis && (
        <div className="cand_score_note">
          Note: Marks Scored & Corect Answers are not Applicable for test with
          only psychometric skill
        </div>
      )}
    </div>
  );
};

export default CandidateMarkReport;
