import React from 'react';
import Accordian from '../../shared/components/UIElements/Accordian';
import Carousel from '../../shared/components/Carousel/Carousel';
import CandidateCheatingAnalysisEnabled from './CandidateCheatingAnalysisEnabled';
import CandidateCheatingAnalysisDisabled from './CandidateCheatingAnalysisDisabled';

const CandidateCheatingPrevention = ({ candidate }) => {
  const reportDetails = candidate.candidateReportDetails;
  return (
    <div className="cheating_prevention">
      <Accordian
        defaultOpen
        containerClass="cheating"
        content={
          <div className="cmn_form_fields cmn_mgn_top">
            <div className="cheating_prev_sec">
              <div className="cheat_prev">
                <div className="cheat_prev_field">
                  <div className="cheat_prev_text">Device Used</div>
                  <div className="cheat_prev_result">
                    {reportDetails.cheatingPrevention.device || 'N/A'}
                  </div>
                </div>
                <div className="cheat_prev_field">
                  <div className="cheat_prev_text">Location</div>
                  <div className="cheat_prev_result">
                    {reportDetails.cheatingPrevention.city || 'N/A'}
                  </div>
                </div>
                {reportDetails.cheatingPrevention.isEnabled ? (
                  <CandidateCheatingAnalysisEnabled
                    cheatingPrevention={reportDetails.cheatingPrevention}
                    webcamImages={reportDetails.webcamImages}
                  />
                ) : (
                  <CandidateCheatingAnalysisDisabled />
                )}
              </div>
              <div className="cheat_prev_carousel">
                <div className="carousel_slides">
                  <Carousel images={reportDetails.webcamImages} />
                </div>
              </div>
            </div>
          </div>
        }
        title={
          <span className="sec_ttl">
            <div className="test_invite_container">
              <p className="report_detail_heading">Cheating Prevention</p>
            </div>
          </span>
        }
      />
    </div>
  );
};

export default CandidateCheatingPrevention;
