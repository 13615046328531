import React from 'react';
import ATag from '../../shared/components/UIElements/ATag';

const SignupTerms = () => {
  return (
    <div className="signup_terms">
      By signing up, you agree to our
      <ATag
        className="cmn_mgn_lef cmn_txt_undlin"
        href="https://www.skillrobo.com/terms-of-service/"
      >
        Terms & Conditions
      </ATag>
      <span className="cmn_mgn_lef">and</span>
      <ATag
        className="privc_policy cmn_txt_undlin"
        href="https://www.skillrobo.com/privacy-policy/"
      >
        Privacy Policy
      </ATag>
    </div>
  );
};

export default SignupTerms;
