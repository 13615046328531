import React, { useEffect, useRef } from 'react';
import TraitScoreProgressBar from './TraitScoreProgressBar';
import useConfirmationDialog from '../../shared/hooks/useConfirmationDialog';
import InfoIcon from '../../shared/components/UIElements/InfoIcon';

const CandidatePsychometricTraitAnalysis = ({ trait }) => {
  const traitDetails = trait.trait;
  const scorePercent = Math.round((trait.marksScored / trait.totalMarks) * 100);
  const traitResultDesc = useRef();

  const { getConfirmation, resetDialog } = useConfirmationDialog();

  const onView = async traitDetailsArgs => {
    await getConfirmation({
      title: traitDetailsArgs.name,
      message: traitDetailsArgs.description,
      cancelText: 'Close',
      isSubmit: false,
    });
  };

  useEffect(() => {
    if (trait.marksAttended) {
      traitDetails.results.some(result => {
        if (result.maximumPercentage >= scorePercent) {
          traitResultDesc.current.innerHTML = result.message;
          return true;
        }
      });
    }
  }, []);

  return (
    <div className="gen_skill_section" key={traitDetails._id}>
      <div className="gen_heading">
        {traitDetails.name}
        <InfoIcon
          className="p5"
          onClick={e => {
            e.stopPropagation();
            onView(traitDetails);
          }}
        />
      </div>
      {trait.marksAttended ? (
        <>
          <div className="gen_skill_desc" ref={traitResultDesc} />
          <div className="gen_skill_results">
            <div className="gen_skill_res_third">
              <div className="gen_skill_res_box">
                <TraitScoreProgressBar scorePercent={scorePercent} />
              </div>
            </div>
          </div>
          {trait.marksAttended !== trait.totalMarks ? (
            <span className="trait_note">
              Note: The candidate didn't complete our questionnaire in this
              trait. This result is based on the questions which he/she
              answered.
            </span>
          ) : null}
        </>
      ) : (
        <span className="trait_no_result">
          The candidate did not attend any questions in this trait. So, the
          results cannot be generated.
        </span>
      )}
    </div>
  );
};

export default CandidatePsychometricTraitAnalysis;
