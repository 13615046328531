import React from 'react';
import { Link } from 'react-router-dom';
import ATag from '../UIElements/ATag';
import LinkButton from '../UIElements/LinkButton';

const Button = props => {
  const { buttonRef, ...rest } = props;
  if (props.buttontype === 'Normal') {
    return (
      <LinkButton>
        <ATag {...props} />
      </LinkButton>
    );
  }
  if (props.buttontype === 'Link' || props.buttontype === 'NavLink') {
    return (
      <LinkButton pretext={props.pretext}>
        <Link {...props}>{props.children}</Link>
      </LinkButton>
    );
  }

  // If button type is dafault
  return (
    <button ref={buttonRef} {...rest}>
      {props.children}
    </button>
  );
};

export default Button;
