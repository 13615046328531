import { useCallback, useContext, useEffect, useState } from 'react';
import { useHttpClient } from './useHttpClient';
import AuthContext from '../contexts/AuthContext';
import useUtils from './useUtils';

export const useSkillsAPI = () => {
  const auth = useContext(AuthContext);
  const { sendRequest, error } = useHttpClient();
  const { getBool } = useUtils();
  const [skillApiError, setSkillApiError] = useState(error);

  useEffect(() => {
    setSkillApiError(error);
  }, [error]);

  // skills
  const getSkillById = useCallback(async (skillId, testId) => {
    const response = await sendRequest(
      testId ? `skills/${skillId}/${testId}` : `skills/${skillId}`,
      'GET'
    );
    return response.skill;
  }, []);

  const getReviewRequestSkillsByContentAdmin = useCallback(async caid => {
    const response = await sendRequest(`skills/request-review/${caid}/`, 'GET');
    return response.skills;
  }, []);

  const getSkillDetailsAndDemoQuestionsById = useCallback(async skillId => {
    const response = await sendRequest(
      `skills/skill-details/${skillId}`,
      'GET'
    );
    return response.skill;
  }, []);

  const getSkillsByTestId = useCallback(async testId => {
    const response = await sendRequest(`skills/test/${testId}`, 'GET');
    return response.skills;
  }, []);

  const getSkillLibrarySkills = useCallback(async () => {
    const response = await sendRequest('skills', 'GET');
    return response.skills;
  }, []);

  const getSkillLibraryContentAdminSkills = useCallback(async () => {
    const response = await sendRequest('skills/getContentAdminSkills', 'GET');
    return response.skills;
  }, []);

  const getCustomSkills = useCallback(async () => {
    const response = await sendRequest(`skills/user/${auth.userId}`, 'GET');
    return response;
  }, []);

  const updateSkill = useCallback(async (skillId, data) => {
    await sendRequest(
      `skills/${skillId}`,
      'PATCH',
      JSON.stringify({
        skillName: data.skillName,
        description: data.description,
        publish: getBool(data?.txtPublish),
        psychometricType: data?.psychometricType ? data.psychometricType : '',
        results: data?.results ? data?.results : [],
        skillDetail: { sdid: null, noOfQuestions: null },
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const approveReviewRequest = useCallback(async (skillId, contentAdminId) => {
    const response = await sendRequest(
      `skills/review-approve`,
      'PATCH',
      JSON.stringify({
        skillId,
        contentAdminId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const sendReviewRequest = useCallback(async skillId => {
    const response = await sendRequest(
      `skills/requestreview/${skillId}`,
      'PATCH'
    );
    return response;
  }, []);

  const rejectReviewRequest = useCallback(async (skillId, contentAdminId) => {
    const response = await sendRequest(
      'skills/rejectreview',
      'PATCH',
      JSON.stringify({
        skillId,
        contentAdminId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const insertSkill = useCallback(async data => {
    const resp = await sendRequest(
      'skills',
      'POST',
      JSON.stringify({
        skillName: data.txtSkillName,
        description: data.txtSkillDesc,
        publish: getBool(data?.txtPublish),
        creator: auth.userId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return resp;
  }, []);

  const contentAdminInsertSkill = useCallback(async data => {
    const resp = await sendRequest(
      'skills/add-to-contentadmin',
      'POST',
      JSON.stringify({
        skillName: data.txtSkillName,
        description: data.txtSkillDesc,
        publish: getBool(data?.txtPublish),
        creator: auth.userId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return resp;
  }, []);

  const deleteSkill = useCallback(async sId => {
    await sendRequest(`skills/${sId}`, 'DELETE');
  }, []);

  const getTestDetailsBySkillId = useCallback(async sId => {
    const resp = await sendRequest(`skills/testDetails/${sId}`, 'GET');
    return resp;
  }, []);

  // skill details
  const insertSkillDetails = useCallback(async (data, skillId, tId) => {
    const resp = await sendRequest(
      'skills/add',
      'PATCH',
      JSON.stringify({
        noOfQuestions: data.txtNoOfQuestions,
        test: data.txtTestName !== undefined ? data.txtTestName.value : tId,
        skill: skillId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return resp;
  }, []);

  const updateSkillDetails = useCallback(async (data, skillDetailId) => {
    await sendRequest(
      `skilldetails/${skillDetailId}`,
      'PATCH',
      JSON.stringify({
        noOfQuestions: data.txtNoOfQuestions,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const getSkillDetailsByTestId = useCallback(async (skillId, testId) => {
    const response = await sendRequest(`skills/${skillId}/${testId}`, 'GET');
    return response.skill;
  }, []);

  const addPsychometricSkill = useCallback(async (skillId, testId) => {
    await sendRequest(`skills/psychometric/${skillId}/${testId}`, 'PATCH');
  }, []);

  const removePsychometricSkill = useCallback(async (skillId, testId) => {
    await sendRequest(`skills/psychometric/${skillId}/${testId}`, 'DELETE');
  }, []);

  const removeSkillDetailsByTestId = useCallback(
    async (sdId, skillId, testId) => {
      await sendRequest(
        `skills/remove/${sdId}`,
        'PATCH',
        JSON.stringify({
          skill: skillId,
          test: testId,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
    },
    []
  );

  return {
    skillApiError,
    getSkillById,
    getReviewRequestSkillsByContentAdmin,
    updateSkill,
    sendReviewRequest,
    rejectReviewRequest,
    contentAdminInsertSkill,
    approveReviewRequest,
    deleteSkill,
    insertSkill,
    getSkillLibrarySkills,
    getSkillLibraryContentAdminSkills,
    getCustomSkills,
    getSkillsByTestId,
    insertSkillDetails,
    updateSkillDetails,
    getSkillDetailsByTestId,
    removeSkillDetailsByTestId,
    getTestDetailsBySkillId,
    getSkillDetailsAndDemoQuestionsById,
    addPsychometricSkill,
    removePsychometricSkill,
  };
};
