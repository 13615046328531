import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeBg from '../../shared/components/UIElements/WelcomeBg';
import Logo from '../../shared/components/UIElements/Logo';
import useLoginContextData from '../../shared/hooks/useLoginContextData';
import './Welcome.css';

const Welcome = () => {
  const navigate = useNavigate();
  let text1 = 'Get ready to explore our refreshing platform';
  let text2 = 'Intuitive skills assessment using AI powered bot ARIV';
  const [welcomeText, setWelcomeText] = useState(text1);
  const [isTextChanged, setIsTextChanged] = useState(false);
  const { userData } = useLoginContextData();
  const [roboGreeting, setRoboGreeting] = useState(' ');
  const [textCounter, setTextCounter] = useState(0);
  const userFullName = JSON.parse(userData)
    ? JSON.parse(userData).userFullName
    : 'Buddy' || 'Buddy';

  const changeOnBoard = () => {
    setTimeout(() => {
      navigate('/on-board');
    }, 2000);
  };

  useEffect(() => {
    const ChangeOverText = setTimeout(() => {
      if (textCounter < welcomeText.length) {
        setRoboGreeting(state => state + welcomeText[textCounter]);
        setTextCounter(state => state + 1);
      }

      if (textCounter == welcomeText.length && !isTextChanged) {
        setTimeout(() => {
          setIsTextChanged(true);
          setTextCounter(0);
          setRoboGreeting(' ');
          setWelcomeText(text2);
        }, 1000);
      }
    }, 50);

    if (textCounter == welcomeText.length && isTextChanged) {
      changeOnBoard();
    }

    return () => {
      clearInterval(ChangeOverText);
    };
  }, [welcomeText, textCounter]);

  return (
    <div className="welcome_floating_container">
      <div className="welcome_container">
        <Logo className="logo_bg_img" />
        <div className="welcome_content_box">
          <div className="user_name_container">
            <h1 className="user_name_text">
              Hey <span className="user_name">{userFullName}!</span>
            </h1>
            <div className="welcome_user_text">{roboGreeting}</div>
          </div>
          <WelcomeBg className="welcome_bg_img" />
        </div>
        <span className="welcome_text">
          <span className="dot-flashing" />
        </span>
      </div>
    </div>
  );
};

export default Welcome;
