import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddCandidateIcon = props => {
  return (
    <Link to={props.to} onClick={props.onClick}>
      <FontAwesomeIcon
        icon={['fas', 'user-plus']}
        className={`icon-person_add1 add_icon ${props.className || null}`}
      />
    </Link>
  );
};

export default AddCandidateIcon;
