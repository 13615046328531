import React from 'react';
import CandidateSkillAnalysisSection from './CanddiateSkillAnalysisSection';

const CandidateOverallSkillsAnalysis = ({ candidate, openPopupToggle }) => (
  <>
    <div className="overall_skill_analysis">
      {candidate.candidateReportDetails.timeDetails.skillsAnalysis ? (
        <CandidateSkillAnalysisSection
          candidate={candidate}
          skills={candidate.candidateReportDetails.selectedAnswers.skills}
          openPopupToggle={openPopupToggle}
        />
      ) : null}
    </div>
    <div className="overall_skill_analysis">
      {candidate.candidateReportDetails.timeDetails.psychometricAnalysis ? (
        <CandidateSkillAnalysisSection
          candidate={candidate}
          skills={
            candidate.candidateReportDetails.selectedAnswers.psychometricSkills
          }
          openPopupToggle={openPopupToggle}
          isPsychometric
        />
      ) : null}
    </div>
  </>
);

export default CandidateOverallSkillsAnalysis;
