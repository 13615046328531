import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserIcon = ({ children, type, ...props }) => (
  <>
    <FontAwesomeIcon
      icon={type === 'light' ? ['far', 'user'] : ['fas', 'user']}
      onClick={props.onClick}
      {...props}
    />
    {children || ''}
  </>
);
export default UserIcon;
