import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const BookAtlasIcon = ({ className, onClick, ...rest }) => {
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon
        icon={['fas', 'book-atlas']}
        className={`${className}`}
      />
    </ATag>
  );
};

export default BookAtlasIcon;
