import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThumbsDownIcon = props => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'thumbs-down']}
      className={`icon-thumb_down_alt1 ${props.className || ''}`}
    />
  );
};

export default ThumbsDownIcon;
