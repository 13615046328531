import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from '../UIElements/InputError';

const TextAreaField = props => {
  const {
    id,
    label,
    className,
    children,
    validation,
    required,
    defaultValue,
    ...rest
  } = props;
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const rules = validation
    ? { ...validation, value: defaultValue || getValues(id) || '' }
    : { required: !!required };

  return (
    <>
      <span
        htmlFor={id}
        className={`cmn_form_label ${validation?.required && 'cmn_required'}`}
      >
        {label}
      </span>
      <div>
        <textarea
          id={id}
          {...rest}
          {...register(id, rules)}
          className={`cmn_form_input ${className} ${
            errors[id] ? 'cmn_form_invalid_input' : ''
          }`}
        />
        {children}
        {errors[id] && <InputError errMsg={errors[id].message} />}
      </div>
    </>
  );
};

export default TextAreaField;
