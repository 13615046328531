import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import InputError from '../UIElements/InputError';

const DtPickerField = props => {
  const {
    id,
    name,
    label,
    className,
    defaultValue,
    validation,
    required,
    tabindex,
    children,
    dateFormat,
    minDateField,
    maxDateField,
    monthPicker,
    ...rest
  } = props;
  const {
    watch,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor={id}
        className={`cmn_form_label ${validation?.required && 'cmn_required'}`}
      >
        {label}
      </label>
      <div>
        <Controller
          control={control}
          id={id}
          name={name}
          defaultValue={defaultValue}
          tabindex={tabindex}
          render={({ field: { onChange, onBlur, value } }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              className={`cmn_form_input ${className} ${
                errors[id] ? 'cmn_form_invalid_input' : ''
              }`}
              dateFormat={dateFormat || 'dd MMM, yyyy'}
              minDate={
                typeof minDateField === 'object'
                  ? minDateField
                  : watch(minDateField)
              }
              maxDate={watch(maxDateField)}
              {...rest}
            />
          )}
          rules={validation || { required: !!required }}
        />
        {children}
        {errors[id] && <InputError errMsg={errors[id].message} />}
      </div>
    </>
  );
};

export default DtPickerField;
