import { createContext } from 'react';

const TestContext = createContext({
  tId: null,
  tName: null,
  sId: null,
  sName: null,
  setTestId: () => {},
  setTestName: () => {},
  setSkillId: () => {},
  setSkillName: () => {},
});
export default TestContext;
