import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from '../ATag';

const MenuBarIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon
        icon={['fas', 'bars']}
        className={`icon-bars ${props.className}`}
      />
    </ATag>
  );
};

export default MenuBarIcon;
