import { useContext } from 'react';
import SnackBarContext from '../contexts/SnackBarContext';
import {
  defaultDuration,
  defaultPosition,
  defaultType,
  positions,
} from '../components/SnackBar/SnackBar';

// To trigger the snackbar on function components
const useSnackbar = ({
  position = defaultPosition,
  style = {},
  closeStyle = {},
} = {}) => {
  const { openSnackbar, closeSnackbar } = useContext(SnackBarContext);

  // Set default position to 'top-left', if nothing mentioned
  if (!positions.includes(position)) {
    position = defaultPosition; // eslint-disable-line no-param-reassign
  }

  const open = (text = '', type = defaultType, duration = defaultDuration) => {
    openSnackbar(text, duration, position, style, closeStyle, type);
  };

  return [open, closeSnackbar];
};

export default useSnackbar;
