import { useCallback } from 'react';

const useUtils = () => {
  const getBool = useCallback(val => {
    if (
      val == null ||
      val === undefined ||
      val === 'null' ||
      val === 'undefined'
    )
      return false;
    if (typeof val === 'boolean') return val === true;
    if (typeof val === 'string') {
      if (val === '') return false;
      return !!JSON.parse(String(val).toLowerCase());
    }
  }, []);
  return { getBool };
};

export default useUtils;
