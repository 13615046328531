import React, { Suspense, lazy, useEffect, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LoaderElement from '../shared/components/Loader/LoaderElement';
import CandidateReport from '../CandidateSummary/Pages/CandidateReport';
import UserHelpContext from '../shared/contexts/UserHelpContext';
import AuthContext from '../shared/contexts/AuthContext';
import useUserHelpContextData from '../shared/hooks/useUserHelpContextData';
import Help from '../shared/components/Help/Help';
import HelpBot from '../shared/components/HelpBot/HelpBot';
import { getUserLocation } from '../shared/utils/Utils';

const TestForm = lazy(() => import('../Tests/Pages/TestForm'));
const MyTests = lazy(() => import('../Tests/Pages/MyTests'));
const SkillLib = lazy(() => import('../SkillsLibs/Pages/SkillsLib'));
const TestLibrary = lazy(() => import('../TestLibrary/Pages/TestLibrary'));
const ViewSkills = lazy(() => import('../Skills/Pages/ViewSkills'));
const NewSkill = lazy(() => import('../Skills/Pages/NewSkill'));
const ImportSkill = lazy(() => import('../Skills/Pages/ImportSkill'));
const EditSkill = lazy(() => import('../Skills/Pages/EditSkill'));
const SkillDetails = lazy(() => import('../Skills/Pages/SkillDetails'));
const EditAddedSkill = lazy(() => import('../Skills/Pages/EditAddedSkill'));
const ViewQuestions = lazy(() => import('../Questions/Pages/ViewQuestions'));
const EditQuestion = lazy(() => import('../Questions/Pages/EditQuestion'));
const AddQuestions = lazy(() => import('../Questions/Pages/AddQuestion'));
const DashboadPage = lazy(() => import('../Dashboard/pages/Dashboard'));
const Profile = lazy(() => import('../Profile/Pages/Profile'));
const CandidateSummary = lazy(() =>
  import('../CandidateSummary/Pages/CandidateSummary')
);
const Candidates = lazy(() => import('../Candidate/Pages/Candidates'));
const SubscriptionPlans = lazy(() =>
  import('../Payment/Pages/SubscriptionPlans')
);
const InviteCandidate = lazy(() =>
  import('../Candidate/Pages/InviteCandidate')
);
const InviteFromCandidates = lazy(() =>
  import('../Candidate/Pages/InviteFromCandidates')
);
const CandidateList = lazy(() => import('../Candidate/Pages/CandidateList'));
const TestConfigs = lazy(() => import('../Tests/Pages/TestConfig'));
const Proctor = lazy(() => import('../Proctor'));

const UserRoute = ({ userRole }) => {
  const {
    isEnabled,
    setStage,
    setIsEnabled,
    stage,
    setUserStage,
    showHelp,
    setShowHelp,
    changeHelpStatus,
    isUserActive,
    setUserActive,
    setcountdown,
    setHelpIsEnable,
    pendingState,
    setPendingState,
    fetchUserStage,
  } = useUserHelpContextData();

  const location = useLocation();

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!localStorage.getItem('sr_geolocation')) {
      getUserLocation()
        .then(res => {
          // location dispatch event for data analysis
          let userLocationEvent = new CustomEvent('userLocationEvent', {
            detail: {
              data: { location: res },
            },
          });

          document.dispatchEvent(userLocationEvent);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (isEnabled) {
      if (stage === 0 || stage === 1) {
        setUserStage(1);
      }
      if (stage === 2) {
        setUserStage(2);
      }
      if (stage === 3) {
        setUserStage(3);
      }
      if (stage === 4) {
        setUserStage(4);
      }

      // stage dispatch event for data analysis
      let userStageEvent = new CustomEvent('userStageEvent', {
        detail: {
          data: { stage: stage },
        },
      });

      document.dispatchEvent(userStageEvent);
    }
  }, [stage, location, showHelp]);

  useEffect(() => {
    async function getData() {
      try {
        let data = await fetchUserStage();
        if (data && data.stage < 5) {
          localStorage.setItem(
            'userHelp',
            JSON.stringify({ isEnabled: true, stage: data.stage })
          );
          setHelpIsEnable(true);
          setUserStage(data.stage);
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (auth && auth.userId) {
      getData();
    }
  }, [auth]);

  return (
    <Suspense fallback={<LoaderElement />}>
      <UserHelpContext.Provider
        value={{
          isEnabled,
          setStage,
          setIsEnabled,
          stage,
          setUserStage,
          showHelp,
          setShowHelp,
          changeHelpStatus,
          isUserActive,
          setUserActive,
          setcountdown,
          setHelpIsEnable,
          pendingState,
          setPendingState,
        }}
      >
        {/* helpbot popup commented on 23/11/2023 */}
        {/* {isEnabled && (
          <Help
            userState={stage}
            location={location}
            changeHelpStatus={changeHelpStatus}
            isUserActive={isUserActive}
            setUserActive={setUserActive}
            setIsEnabled={setIsEnabled}
            setHelpIsEnable={setHelpIsEnable}
            pendingState={pendingState}
          />
        )} */}
        {isEnabled && <HelpBot />}
        <Routes>
          <Route path="/dashboard" element={<DashboadPage />} />
          <Route path="/profile" element={<Profile />} />
          {userRole === 'admin' && (
            <Route path="/subscription/plans" element={<SubscriptionPlans />} />
          )}
          <Route path="/profile/:tab" element={<Profile />} />
          <Route path="/tests/new" element={<TestForm />} />
          <Route path="/:tid/skills" element={<ViewSkills />} />
          <Route path="/tests/:tid" element={<TestForm />} />
          <Route path="/:uid/tests" element={<MyTests />} />
          <Route path="/skills/new" element={<NewSkill />} />
          <Route path="/skills/:sid/importskill" element={<ImportSkill />} />
          <Route
            path="/:tid/skills/:sid/addtotest/:edit"
            element={<EditAddedSkill />}
          />
          <Route
            path="/:tid/skills/:sid/addtotest"
            element={<EditAddedSkill />}
          />
          <Route path="/skills/:sid" element={<EditSkill />} />
          <Route path="/skill-details/:sid" element={<SkillDetails />} />
          <Route path="/skill-library" element={<SkillLib />} />
          <Route path="/skill-library/:searchQuery" element={<SkillLib />} />
          <Route path="/test-library" element={<TestLibrary />} />
          <Route path="/test-library/:searchQuery" element={<TestLibrary />} />
          <Route path="/:tid/importskills" element={<SkillLib />} />
          <Route path="/:sid/questions/new" element={<AddQuestions />} />
          <Route path="/:sid/questions" element={<ViewQuestions />} />
          <Route path="/:tid/invite" element={<InviteCandidate />} />
          <Route path="/invite" element={<InviteFromCandidates />} />
          <Route path="/:tid/testconfig" element={<TestConfigs />} />

          <Route
            path="/:tid/candidates/:cid/summary"
            element={<CandidateSummary />}
          />
          <Route path="/:tid/candidates" element={<CandidateList />} />
          <Route path="/:sid/questions/:qid" element={<EditQuestion />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route
            path="/candidate/report/:tid/:cid/:reportToken"
            element={<CandidateReport />}
          />
          <Route path="/proctor" element={<Proctor />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </UserHelpContext.Provider>
    </Suspense>
  );
};

export default UserRoute;
