import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyIcon = ({ className }) => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'copy']}
      className={`icon-documents4 ${className}`}
    />
  );
};

export default CopyIcon;
