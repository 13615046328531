import React from 'react';
import DefaultPic from '../../images/person.png';

const UserPic = props => {
  const pic = props.src ?? DefaultPic;
  return (
    <>
      <img src={pic} alt="user" />
    </>
  );
};
export default UserPic;
