import React from 'react';
import PoweredBySkillRobo from '../../images/powered by skillrobo clr.png';

const PoweredByLogo = props => (
  <img
    src={PoweredBySkillRobo}
    alt="powred_by_skillrobo_logo"
    className={props.className}
  />
);

export default PoweredByLogo;
