import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../shared/components/FormElements/Button';
import InputField from '../../shared/components/FormElements/InputField';
import Logo from '../../shared/components/UIElements/Logo';
import { Email_Regex } from '../../shared/constants/AppConstants';
import EmailSent from '../../shared/images/undraw_confidential_letter_w6ux-removebg-preview.png';

const ResetPasswordConfirmPassword = props => {
  return (
    <>
      {props.emailSent ? (
        <div className="cad_fed_img_container">
          <img src={EmailSent} alt="icon" />
          <div className="can_login_hsub_eading">
            You will receive an email with a reset password link.
          </div>
          <div className="can_login_hsub_eading">Please check it..</div>
        </div>
      ) : (
        <div className="can_login_second">
          <div className="can_mobile_logo">
            <Logo />
          </div>
          <div className="can_login_first_top">
            <div className="can_login_heading"> Reset your password</div>
          </div>
          <div className="can_login_form">
            <div className="cmn_form_input_container cmn_wi_full">
              <InputField
                id="email"
                name="email"
                type="email"
                label="Email"
                placeholder=""
                validation={{
                  required: 'Required',
                  pattern: {
                    value: Email_Regex,
                    message: 'Invalid email address',
                  },
                }}
              />
            </div>

            <div className="can_login_btn">
              <Button className="cad_primary_btn submit_reset">Send</Button>
            </div>
          </div>
          <div className="can_return">
            Return to <Link to="/login">Login</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPasswordConfirmPassword;
