import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const FolderPlus = props => {
  const { onClick, ...rest } = props;
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon
        icon={['fas', 'folder-plus']}
        className={`icon-plus ${props.className}`}
      />
    </ATag>
  );
};

export default FolderPlus;
