import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const Form = ({
  initialValue,
  children,
  onSubmit,
  onChange,
  getFormMethods,
  ...props
}) => {
  const methods = useForm({
    defaultValues: initialValue,
    mode: 'onBlur',
    shouldUnregister: true,
  });

  const watchedFields = methods.watch(); // Watch all fields

  useEffect(() => {
    // Trigger the onChange callback with the watched fields
    if (onChange) onChange(watchedFields);
  }, [watchedFields]);

  useEffect(() => {
    if (getFormMethods) getFormMethods(methods);
  }, []);

  return (
    <FormProvider {...methods} noValidate>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
export default Form;
