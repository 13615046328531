import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoaderElement from '../shared/components/Loader/LoaderElement';

const AddQuestions = lazy(() => import('../Admin/Questions/Pages/AddQuestion'));
const EditQuestion = lazy(() =>
  import('../Admin/Questions/Pages/EditQuestion')
);
const EditAddedSkill = lazy(() =>
  import('../Admin/Skills/Pages/EditAddedSkill')
);
const EditSkill = lazy(() => import('../Admin/Skills/Pages/EditSkill'));
const ViewSkills = lazy(() => import('../Admin/Skills/Pages/ViewSkills'));
const ViewTraits = lazy(() => import('../Admin/Traits/Pages/ViewTraits'));
const NewSkill = lazy(() => import('../Skills/Pages/NewSkill'));
const Trait = lazy(() => import('../Admin/Traits/Pages/Trait'));
const ViewQuestions = lazy(() =>
  import('../Admin/Questions/Pages/ViewQuestions')
);
const AdminSkillLib = lazy(() =>
  import('../Admin/SkillsLibrary/Pages/AdminSkillsLib')
);
const AdminTestLib = lazy(() =>
  import('../Admin/TestLibrary/Pages/AdminTestLib')
);
const AdminTestForm = lazy(() =>
  import('../Admin/TestLibrary/Pages/AdminTestForm')
);
const AddContentAdmin = lazy(() =>
  import('../Admin/ContentAdmin/Pages/AddContentAdmin')
);

const RequestReview = lazy(() =>
  import('../Admin/ContentAdmin/Pages/ReviewRequest')
);

const RequestReviewQuestions = lazy(() =>
  import('../Admin/ContentAdmin/Pages/RequestReviewQuestions')
);

const AdminRoute = () => (
  <Suspense fallback={<LoaderElement />}>
    <Routes>
      <Route exact path="/" element={<AdminSkillLib />} />
      <Route exact path="/skill-library" element={<AdminSkillLib />} />
      <Route exact path="/skills/new" element={<NewSkill />} />
      <Route exact path="/skills/:sid" element={<EditSkill />} />

      <Route exact path="/traits" element={<ViewTraits />} />
      <Route exact path="/:sid/traits/import" element={<ViewTraits />} />
      <Route exact path="/traits/new" element={<Trait />} />
      <Route exact path="/traits/:tid" element={<Trait />} />
      <Route exact path="/traits/new" element={<Trait />} />
      <Route
        exact
        path="/trait/:tid/questions/new"
        element={<AddQuestions />}
      />
      <Route exact path="/trait/:tid/questions" element={<ViewQuestions />} />
      <Route
        exact
        path="/trait/:tid/questions/:qid"
        element={<EditQuestion />}
      />

      <Route exact path="/:sid/questions/new" element={<AddQuestions />} />
      <Route exact path="/:sid/questions" element={<ViewQuestions />} />
      <Route exact path="/:sid/questions/:qid" element={<EditQuestion />} />

      <Route exact path="/test-library" element={<AdminTestLib />} />
      <Route path="/:tid/importskills" element={<AdminSkillLib />} />
      <Route path="/:tid/skills" element={<ViewSkills />} />
      <Route exact path="/tests/new" element={<AdminTestForm />} />
      <Route path="/tests/:tid" element={<AdminTestForm />} />

      <Route
        path="/:tid/skills/:sid/addtotest"
        element={<EditAddedSkill />}
        exact
      />
      <Route
        path="/:tid/skills/:sid/addtotest/:edit"
        element={<EditAddedSkill />}
        exact
      />

      <Route path="/content-admin" element={<AddContentAdmin />} />

      <Route path="/review-skills/:caid" element={<RequestReview />} />

      <Route
        path="/review-questions/:sid/:caid"
        element={<RequestReviewQuestions />}
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </Suspense>
);

export default AdminRoute;
