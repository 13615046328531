import React from 'react';

const CandidateTestDetails = ({ candidate }) => {
  let candidateResult;
  switch (candidate.resultStatus) {
    case 'pass':
      candidateResult = <p className="cand_rep_pass">Pass</p>;
      break;
    case 'fail':
      candidateResult = <span className="cand_rep_fail">Fail</span>;
      break;
    case 'cheated':
      candidateResult = <span className="can_rep_cheated">Cheated</span>;
      break;
    default:
      candidateResult = <></>;
  }
  return (
    <div className="candidate_rep_det_sec border_right">
      {candidateResult}
      <p>
        <span>Assesment Name: </span>
        <span className="cand_rep_det_bold">{candidate.test.testName}</span>
      </p>
      <p>
        <span>Invited Date: </span>
        <span className="cand_rep_det_bold">{candidate.invitedAt}</span>
      </p>
      <p>
        <span>Started At: </span>
        <span className="cand_rep_det_bold">
          {candidate.candidateReportDetails.startedAt}
        </span>
      </p>
      <p>
        <span>Submitted At: </span>
        <span className="cand_rep_det_bold">
          {candidate.candidateReportDetails.submittedAt}
        </span>
      </p>
    </div>
  );
};

export default CandidateTestDetails;
