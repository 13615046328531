/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useComponentVisible from '../../shared/hooks/ComponentVisible';
import AuthContext from '../../shared/contexts/AuthContext';
import SignOutIcon from '../../shared/components/UIElements/SignOutIcon/SignOutIcon';
import ATag from '../../shared/components/UIElements/ATag';
import Logo from '../../shared/components/UIElements/Logo';
import MenuBarIcon from '../../shared/components/UIElements/MenuBarIcon/MenuBarIcon';
import UserPic from '../../shared/components/UIElements/UserProfile';
import useConfirmationDialog from '../../shared/hooks/useConfirmationDialog';
import UsersLoginForm from '../AdminDelegation/UsersLoginForm';
import './navbar.css';

const TopNavBar = props => {
  const navigate = useNavigate();
  const { ref, isComponentVisible, setisComponentVisible } =
    useComponentVisible(false);
  const { getConfirmation, resetDialog } = useConfirmationDialog();
  const auth = useContext(AuthContext);
  const toggleUserSetup = () => {
    setisComponentVisible(!isComponentVisible);
  };

  const adminLogout = () => {
    auth.logout();
    navigate('/admin/login');
  };

  const delegateLogin = responseData => {
    auth.logout();
    auth.login(
      responseData.userId,
      responseData.name,
      responseData.image,
      responseData.email,
      responseData.logo,
      responseData.token,
      responseData.role,
      responseData.clientId,
      false, // master admin
      responseData.isAdditionalDetailsFilled,
      responseData.emailSettings,
      responseData.emailTemplates,
      {
        plan: responseData.plan,
        clientDomain: responseData.clientDomain,
        subscriptionDate: responseData.subscriptionDate,
        subscriptionExpiry: responseData.subscriptionExpiry,
      },
      true // set login user as delegated user
    );
  };

  const mobileMenu = () => {
    props.setisComponentVisible(!props.isComponentVisible);
  };
  const uData = JSON.parse(auth.userData);

  const onDelegateAdminToggle = async () => {
    await getConfirmation({
      title: 'Delegate Login',
      message: (
        <UsersLoginForm
          resetDialog={resetDialog}
          delegateLogin={delegateLogin}
        />
      ),
    });
  };

  return (
    <div className="navbar_container clr_bg_white cmn_box_shadow_0">
      <div className="navbar_logo">
        <div
          className="mbl_mnu"
          ref={props.mbl_menu}
          onClick={mobileMenu}
          role="button"
          tabIndex={0}
        >
          <MenuBarIcon className="icon-menu6" />
        </div>
        <Logo />
      </div>

      <div className="navbar_right_container">
        <div>
          <button
            className="cmn_btn cmn_primary_txt"
            onClick={onDelegateAdminToggle}
          >
            Delegate Login
          </button>
        </div>
        <div className="navbar_profile" ref={ref}>
          <ATag className="navbar_profile_pic" onClick={toggleUserSetup}>
            <UserPic src={uData.profilePic} />
            <span className="navbar_loged_user_name">{uData.userFullName}</span>
          </ATag>
          <div
            className={`navbar_profile_dropdown ${
              isComponentVisible ? 'cmn_show' : ''
            }`}
          >
            <ATag onClick={adminLogout} className="dropdown_item cmn_icon_txt">
              <SignOutIcon className="icon-logout1" />
              <span>Sign out</span>
            </ATag>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
