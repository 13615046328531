/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import StarIcon from '../UIElements/Stars/Stars';

const Stars = props => {
  return (
    <div className="cad_fed_star" role="cell">
      <StarIcon
        className={
          props.feedbackRating >= 1 ? 'icon-stargrade1' : 'icon-star_outline1'
        }
      />
      <StarIcon
        className={
          props.feedbackRating >= 2 ? 'icon-stargrade1' : 'icon-star_outline1'
        }
      />
      <StarIcon
        className={
          props.feedbackRating >= 3 ? 'icon-stargrade1' : 'icon-star_outline1'
        }
      />
      <StarIcon
        className={
          props.feedbackRating >= 4 ? 'icon-stargrade1' : 'icon-star_outline1'
        }
      />
      <StarIcon
        className={
          props.feedbackRating >= 5 ? 'icon-stargrade1' : 'icon-star_outline1'
        }
      />
    </div>
  );
};

export default Stars;
