import React, { useRef, useEffect, useContext } from 'react';
import Form from '../FormElements/Form';
import FormSubmitControl from '../FormElements/FormSubmitControl';
import AuthContext from '../../contexts/AuthContext';
import { useUserAPI } from '../../hooks/useUserAPI';
import useSnackbar from '../../hooks/SnackBarHook';
import '../Popup/popup.css';

const ClientLogoUploadPopup = props => {
  const clientLogo = useRef();
  const auth = useContext(AuthContext);
  const uData = JSON.parse(auth.userData);

  const { updateLogo, error } = useUserAPI();
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onSubmit = () => {
    const updateClientLogo = async () => {
      try {
        const resp = await updateLogo(
          localStorage.getItem('uid'),
          clientLogo.current.files[0]
        );
        props.setShowClientUploadPopup(false);
        openSnackbar('Logo uploaded successfully', 'success');
        auth.setUserDetails(JSON.stringify({ ...uData, logo: resp.logo }));
      } catch (err) {
        setTimeout(() => {
          clientLogo.current.value = '';
          props.setShowClientUploadPopup(false);
        });
        console.error(err);
      }
    };
    if (clientLogo.current.files[0]) {
      updateClientLogo();
    }
  };

  return (
    <div id="popup3" className="cmn_overlay">
      <div className="cmn_popup small_popup">
        <Form onSubmit={onSubmit}>
          <div className="cmn_pop_head">
            <h2> Logo Setting</h2>
          </div>
          <div className="cmn_pop_content">
            <div className="cmn_form_input_container cmn_wi_full">
              <div className="logo_pop_container">
                <div>
                  <input
                    type="file"
                    name="logo_upload"
                    ref={clientLogo}
                    className="cmn_form_input"
                    accept="image/*"
                  />

                  <p className="cmn_form_note">
                    Note : By default the image will be resized to 200*80
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cmn_pop_footer">
            <FormSubmitControl
              submitButtonText="Upload"
              onCancel={() => {
                props.setShowClientUploadPopup(false);
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ClientLogoUploadPopup;
