import React from 'react';

const CandidateCheatingAnalysisDisabled = () => (
  <>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text ">Webcam enabled?</div>
      <div className="cht_prev_res_na">N/A</div>
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Full-screen mode always active?</div>
      <div className="cht_prev_res_na">N/A</div>
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Mouse always in assessment window?</div>
      <div className="cht_prev_res_na">N/A</div>
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Resized assessment window?</div>
      <div className="cht_prev_res_na">N/A</div>
    </div>
  </>
);

export default CandidateCheatingAnalysisDisabled;
