import React from 'react';

const CandidateCheatingAnalysisEnabled = ({
  cheatingPrevention,
  webcamImages,
}) => (
  <>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Webcam enabled?</div>
      {webcamImages.length > 0 ? (
        <div className="cheat_prev_res_yes">Yes</div>
      ) : (
        <div className="cheat_prev_res_no">No</div>
      )}
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Full-screen mode always active?</div>
      {cheatingPrevention.fraudCount.exitFullscreen === 0 ? (
        <div className="cheat_prev_res_yes">Yes</div>
      ) : (
        <div className="cheat_prev_res_no">No</div>
      )}
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Mouse always in assessment window?</div>
      {cheatingPrevention.fraudCount.pageOutOfFocus === 0 ? (
        <div className="cheat_prev_res_yes">Yes</div>
      ) : (
        <div className="cheat_prev_res_no">No</div>
      )}
    </div>
    <div className="cheat_prev_field">
      <div className="cheat_prev_text">Resized assessment window?</div>
      {cheatingPrevention.fraudCount.resize > 0 ? (
        <div className="cheat_prev_res_no">Yes</div>
      ) : (
        <div className="cheat_prev_res_yes">No</div>
      )}
    </div>
  </>
);

export default CandidateCheatingAnalysisEnabled;
