import React from 'react';

const InputError = props => {
  return (
    <>
      <span className="cmn_form_invalid_msg">{props.errMsg}</span>
    </>
  );
};
export default InputError;
