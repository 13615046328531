/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InputError from '../UIElements/InputError';
import InvisibilityIcon from '../UIElements/InvisibilityIcon';

import VisibilityIcon from '../UIElements/VisibilityIcon';

const InputField = props => {
  const [showPassword, setShowPassword] = useState(false);
  const TogglePasswordAvailability = () => {
    setShowPassword(!showPassword);
  };

  const {
    id,
    label,
    className,
    children,
    validation,
    required,
    type,
    isShowPassword,
    ...rest
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  let inputType = type;
  if (isShowPassword) inputType = showPassword ? 'text' : 'password';
  return (
    <>
      {label && (
        <span
          htmlFor={id}
          className={`cmn_form_label ${validation?.required && 'cmn_required'}`}
        >
          {label}
        </span>
      )}

      <div>
        <input
          {...register(id, validation || { required: required })}
          {...rest}
          className={`cmn_form_input ${className} ${
            errors[id] ? 'cmn_form_invalid_input' : ''
          }`}
          type={inputType}
        />
        {children}
        {isShowPassword ? (
          <span className="eye_pos_rel" onClick={TogglePasswordAvailability}>
            {showPassword ? <InvisibilityIcon /> : <VisibilityIcon />}
          </span>
        ) : null}
        {errors[id] && <InputError errMsg={errors[id].message} />}
      </div>
    </>
  );
};
export default InputField;
