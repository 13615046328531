/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import UserIcon from '../UIElements/UserIcon';
import CardIcon from '../CardIcon/CardIcon';
import MenuBarIcon from '../UIElements/MenuBarIcon/MenuBarIcon';
import SignOutIcon from '../UIElements/SignOutIcon/SignOutIcon';
import ATag from '../UIElements/ATag';
import AuthContext from '../../contexts/AuthContext';
import UserPic from '../UIElements/UserProfile';
import useComponentVisible from '../../hooks/ComponentVisible';
import PoweredByLogo from '../UIElements/PoweredByLogo';
import ClientLogo from '../UIElements/ClientLogo';
import HelpIcon from '../UIElements/HelpIcon';

const TopNavBar = props => {
  const { ref, isComponentVisible, setisComponentVisible } =
    useComponentVisible(false);

  const auth = useContext(AuthContext);
  const toggleUserSetup = () => {
    setisComponentVisible(!isComponentVisible);
  };

  const mobileMenu = () => {
    props.setisComponentVisible(!props.isComponentVisible);
  };

  const uData = JSON.parse(auth.userData);
  return (
    <div className="navbar_container clr_bg_white cmn_box_shadow_0">
      <div className="top_left">
        <div
          className="mbl_mnu"
          ref={props.mbl_menu}
          onClick={mobileMenu}
          role="button"
          tabIndex={0}
        >
          <MenuBarIcon className="icon-menu6" />
        </div>
        <div className="client_logo">
          <ATag
            className="logo_settings"
            onClick={() => {
              props.setShowClientUploadPopup(true);
            }}
          >
            <ClientLogo src={uData.logo} className="logo_img" />
          </ATag>
        </div>
      </div>
      <div className="top_right">
        <div className="navbar_help">
          <HelpIcon
            href="https://help.skillrobo.com/"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
        <div className="navbar_right_container">
          <div className="navbar_profile" ref={ref}>
            <ATag className="navbar_profile_pic" onClick={toggleUserSetup}>
              <UserPic src={uData.profilePic} />
              <span className="navbar_loged_user_name">
                {uData.userFullName}
              </span>
            </ATag>
            <div
              className={`navbar_profile_dropdown ${
                isComponentVisible ? 'cmn_show' : ''
              }`}
            >
              <Link to="/profile" className="dropdown_item cmn_icon_txt">
                {/* <i className="icon-user5" /> */}
                <UserIcon className="icon-user5" type="light" />
                <span>Profile</span>
              </Link>
              {localStorage.getItem('uRole') === 'admin' && (
                <Link
                  to="/subscription/plans"
                  className="dropdown_item cmn_icon_txt"
                >
                  <CardIcon className="icon-credit_cardpayment1" />
                  <span>Payment Plans</span>
                </Link>
              )}
              {/* <Link to="/settings" className="dropdown_item cmn_icon_txt">
                <i className="icon-settings_gear" />
                <span>Settings</span>
              </Link>
              <Link to="/help" className="dropdown_item cmn_icon_txt">
                <i className="icon-help-circle" />
                <span>Need help</span>
              </Link> */}
              <ATag
                onClick={() => auth.logout()}
                className="dropdown_item cmn_icon_txt"
              >
                <SignOutIcon className="icon-logout1" />
                <span>Sign out</span>
              </ATag>
            </div>
          </div>
        </div>
        <PoweredByLogo className="powred_by_sk_logo" />
      </div>
    </div>
  );
};

export default TopNavBar;
