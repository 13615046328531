import { useState, useEffect } from 'react';

export const useOnlineStatus = () => {
  function checkIsUserOnline() {
    if (window && 'navigator' in window && 'onLine' in navigator) {
      return navigator.onLine;
    }
    return true;
  }
  const [isOnline, setIsOnline] = useState(checkIsUserOnline());
  const updateOnlineStatus = () => {
    setIsOnline(checkIsUserOnline());
  };
  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);
  return { isOnline };
};
