import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import CandidateReviewDetails from '../Component/CandidateReviewDetails';
import CandidateOverallSkillsAnalysis from '../Component/CandidateOverallSkillsAnalysis';
import CandidateSummaryDetails from '../Component/CandidateSummaryDetails';
import CandidateTestDetails from '../Component/CandidateTestDetails';
import CandidateMarkReport from '../Component/CandidateMarkReport';
import CandidateCheatingPrevention from '../Component/CandidateCheatingPrevention';
import CandidateSummaryTopDetails from '../Component/CandidateSummaryTopDetails';
import CandidateQuestionsPopup from './CandidateQuestionsPopup';
import CandidateFeedback from '../Component/CandidateFeedback';
import { getFormatedDateTime, getFormatedDate } from '../../shared/utils/Utils';
import useConfirmationDialog from '../../shared/hooks/useConfirmationDialog';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import { useCandidateAPI } from '../../shared/hooks/useCandidateAPI';
import PoweredByLogo from '../../shared/components/UIElements/PoweredByLogo';
import SkillRoboLogo from '../../shared/images/skillrobo logo.png';
import './CandidateSummary.css';
import './CandidateReport.css';

let currentActiveSkill = '';
const CandidateReport = () => {
  const [openSnackbar] = useSnackbar();
  const uData = JSON.parse(localStorage.getItem('user'));
  const { cid, reportToken } = useParams();
  const [candidateSummary, setCandidateSummary] = useState({});
  const { resetDialog } = useConfirmationDialog();
  const { getCandidatePublicDetails, error } = useCandidateAPI();
  const [toggleQuesPopup, setToggleQuesPopup] = useState(false);

  const openPopupToggle = useCallback(skill => {
    currentActiveSkill = skill;
    setToggleQuesPopup(true);
  }, []);

  const closePopupToggle = useCallback(() => {
    setToggleQuesPopup(false);
  }, []);

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  useEffect(() => {
    const getCandidateDetails = async () => {
      try {
        const candidateSummaryData = await getCandidatePublicDetails(
          cid,
          reportToken
        );
        candidateSummaryData.invitedAt = getFormatedDate(
          new Date(candidateSummaryData.createdAt)
        );
        if (candidateSummaryData.candidateReportDetails.startedAt) {
          const startedDate = new Date(
            candidateSummaryData.candidateReportDetails.startedAt
          );

          candidateSummaryData.candidateReportDetails.submittedAt =
            getFormatedDateTime(
              new Date(candidateSummaryData.candidateReportDetails.submittedAt)
            );

          candidateSummaryData.candidateReportDetails.startedAt =
            getFormatedDateTime(startedDate);
        } else {
          candidateSummaryData.candidateReportDetails.startedAt = 'N/A';
          candidateSummaryData.candidateReportDetails.submittedAt = 'N/A';
        }
        setCandidateSummary(candidateSummaryData);
      } catch (err) {
        console.error(err);
      }
    };
    getCandidateDetails();

    return () => {
      resetDialog();
    };
  }, [cid]);

  return (
    <>
      {candidateSummary._id && (
        <div className="cmn_form_container">
          <div className="can_rep_con_report">
            <div className="report_container">
              <CandidateSummaryTopDetails
                clientLogo={SkillRoboLogo}
                PoweredByLogo={PoweredByLogo}
              />
              <div className="candidate_summary_sec">
                <div className="candidate_summary_det">
                  <CandidateSummaryDetails candidateData={candidateSummary} />
                  <CandidateTestDetails candidate={candidateSummary} />
                  <CandidateMarkReport
                    candidateReportDetails={
                      candidateSummary.candidateReportDetails
                    }
                  />
                </div>
                <CandidateReviewDetails candidate={candidateSummary} />
              </div>
            </div>
            <div className="candidatae_overview">
              <CandidateOverallSkillsAnalysis
                candidate={candidateSummary}
                openPopupToggle={openPopupToggle}
              />
              <CandidateCheatingPrevention candidate={candidateSummary} />
              <CandidateFeedback
                feedback={candidateSummary.candidateReportDetails.feedback}
              />
            </div>
            {toggleQuesPopup && (
              <CandidateQuestionsPopup
                closePopupToggle={closePopupToggle}
                skillDetails={currentActiveSkill}
              />
            )}
          </div>
        </div>
      )}
      {toggleQuesPopup && (
        <CandidateQuestionsPopup
          closePopupToggle={closePopupToggle}
          skillDetails={currentActiveSkill}
        />
      )}
    </>
  );
};

export default CandidateReport;
