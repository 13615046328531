import React from 'react';
import Accordian from '../../shared/components/UIElements/Accordian';
import SkillSummarySection from './SkillSummarySection';

const CandidateSkillAnalysisSection = ({
  candidate,
  openPopupToggle,
  isPsychometric,
  skills,
}) => (
  <Accordian
    defaultOpen
    containerClass={
      isPsychometric
        ? 'cnd_ovrl_psychSkill_analysis'
        : 'cnd_ovrl_skill_analysis'
    }
    content={
      <div className="cmn_form_fields cmn_mgn_top">
        <div className="overall_report_section">
          <SkillSummarySection
            candidate={candidate}
            isPsychometric={isPsychometric}
            skills={skills}
            openPopupToggle={openPopupToggle}
          />
        </div>
      </div>
    }
    title={
      <span className="sec_ttl">
        <div className="test_invite_container">
          {isPsychometric ? (
            <p className="report_detail_heading">Psychometric Analysis</p>
          ) : (
            <>
              <p className="report_detail_heading">Skill Analysis</p>
              <div className="skill_legend_section">
                <div className="skill_leg_type">
                  <div className="leg_box skill_review_excellent" />
                  <div className="leg_text">Excellent</div>
                </div>
                <div className="skill_leg_type">
                  <div className="leg_box skill_review_great" />
                  <div className="leg_text">Great</div>
                </div>
                <div className="skill_leg_type">
                  <div className="leg_box skill_review_good" />
                  <div className="leg_text">Good</div>
                </div>
                <div className="skill_leg_type">
                  <div className="leg_box skill_review_fair" />
                  <div className="leg_text">Fair</div>
                </div>
                <div className="skill_leg_type">
                  <div className="leg_box skill_review_poor" />
                  <div className="leg_text">Poor</div>
                </div>
              </div>
            </>
          )}
        </div>
      </span>
    }
  />
);

export default CandidateSkillAnalysisSection;
