import { useCallback } from 'react';
import { useHttpClient } from './useHttpClient';
import { getParsedData } from '../utils/CandidateUtils';

export const useCandidateAPI = () => {
  const { sendRequest, error } = useHttpClient();

  const inviteCandidateByEmail = useCallback(async (testId, data) => {
    const response = await sendRequest(
      'candidates',
      'POST',
      JSON.stringify({
        emails: data.candidate_emails,
        test: testId,
        inviteSubject: data.invite_subject,
        inviteMessage: data.invite_message,
        submitSubject: data.submit_subject,
        submitMessage: data.submit_message,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    // invite dispatch event for data analysis
    let userInviteEvent = new CustomEvent('userInviteEvent', {
      detail: {
        data: { inviteCount: data.candidate_emails.split(',').length },
      },
    });

    document.dispatchEvent(userInviteEvent);
    return response;
  }, []);

  const getCandidatesByUser = useCallback(async () => {
    const response = await sendRequest(`candidates/user`, 'GET');
    return response.candidates;
  }, []);

  const shareCandidateReportEmail = useCallback(
    async (
      reportToken,
      cid,
      candidateName,
      testId,
      testName,
      userEmail,
      userName,
      email,
      submittedDate
    ) => {
      const response = await sendRequest(
        `candidates/candidate/share-report/${cid}`,
        'POST',
        JSON.stringify({
          reportToken,
          candidateName,
          testId,
          testName,
          userEmail,
          userName,
          email,
          submittedDate,
        }),
        { 'Content-Type': 'application/json' }
      );
      return response.reportURL;
    },
    []
  );

  const getCandidateListByTest = useCallback(async testId => {
    const response = await sendRequest(`candidates/user/test/${testId}`, 'GET');
    return response.candidates;
  }, []);

  const getOnlineCandidates = useCallback(async isLoading => {
    const response = await sendRequest(
      `candidates/getOnlineCandidates`,
      'GET',
      null,
      {},
      false,
      isLoading
    );
    return response.candidates;
  }, []);

  const getCandidateReportByTest = useCallback(async testId => {
    const response = await sendRequest(
      `candidates/user/testReport/${testId}`,
      'GET'
    );
    return response.candidateData;
  }, []);

  const getCandidateSummaryDetails = useCallback(async candidateId => {
    const response = await sendRequest(
      `candidates/candidate/${candidateId}`,
      'GET'
    );
    return getParsedData(response.candidate);
  }, []);

  const getCandidatePublicDetails = useCallback(async (cid, token) => {
    const response = await sendRequest(
      `candidates/candidate/public/${cid}/${token}`,
      'GET',
      null,
      {},
      true
    );
    return getParsedData(response.candidate);
  }, []);

  const getCandidateImages = useCallback(async cId => {
    const response = await sendRequest(
      `candidates/candidate/images/${cId}`,
      'GET',
      null
    );
    return response;
  }, []);

  const reinviteCandidate = useCallback(async candidateIds => {
    const response = await sendRequest(
      `candidates/reinvite`,
      'POST',
      JSON.stringify({
        candidates: candidateIds,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const revokeCandidateinvite = useCallback(async candidateIds => {
    const response = await sendRequest(
      `candidates/revoke`,
      'POST',
      JSON.stringify({
        candidates: candidateIds,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const shortlistCandidate = useCallback(async candidateId => {
    await sendRequest(
      `candidates/shortlist`,
      'POST',
      JSON.stringify({ candidates: candidateId }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const removeShortlistedCandidate = useCallback(async candidateId => {
    await sendRequest(
      `candidates/shortlist`,
      'DELETE',
      JSON.stringify({ candidates: candidateId }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  return {
    error,
    inviteCandidateByEmail,
    getCandidateListByTest,
    getCandidateReportByTest,
    getCandidateSummaryDetails,
    getOnlineCandidates,
    getCandidatePublicDetails,
    reinviteCandidate,
    revokeCandidateinvite,
    getCandidateImages,
    shareCandidateReportEmail,
    shortlistCandidate,
    removeShortlistedCandidate,
    getCandidatesByUser,
  };
};
