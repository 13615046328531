import React from 'react';
import Form from '../../shared/components/FormElements/Form';
import AdditionalDetailsFormElements from './AdditionalDetailsFormElements';

const OnboardStep1 = ({ onSubmit }) => {
  return (
    <div className="additional-form">
      <p>Share some details to customize your experience</p>

      <Form onSubmit={onSubmit}>
        <AdditionalDetailsFormElements />
      </Form>
    </div>
  );
};

export default OnboardStep1;
