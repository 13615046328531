import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const HelpIcon = props => {
  return (
    <ATag onClick={props.onClick} {...props}>
      <FontAwesomeIcon
        icon={['far', 'circle-question']}
        className="icon-help-circle"
      />
      {/* <i className="icon-help-circle" /> */}
    </ATag>
  );
};

export default HelpIcon;
