import React from 'react';
import PieCharts from '../../shared/components/Charts/PieCharts';
import CandidateSkillReview from './CandidateSkillReview';

const CandidateReviewDetails = ({ candidate }) => {
  let candidateSignalStatus = '';
  let recommendationSignalClass;
  let timeLeft = 0;
  let timeAllocated = 0;
  const strongSkills = [];
  const improvementSkills = [];
  let generalSkills = [];
  let skillsPercentage;
  let recommendedPercentage;
  let psychometricScorePercentage;
  let scorePercentage;
  let pieChartData = {};

  if (candidate.candidateReportDetails.timeDetails.skillsAnalysis) {
    // general skills means all the skills except psychometric skills
    generalSkills = [
      ...candidate.candidateReportDetails.selectedAnswers.skills,
    ];
    timeLeft +=
      candidate.candidateReportDetails.timeDetails.skillsAnalysis.timeLeft || 0;
    timeAllocated +=
      candidate.candidateReportDetails.timeDetails.skillsAnalysis.allocatedTime;
    generalSkills.forEach(skill => {
      // calculating stong skills and skills that need improvement
      if (skill.scoredPercentage >= 60) {
        strongSkills.push(skill.skill.skillName);
      } else {
        improvementSkills.push(skill.skill.skillName);
      }
    });
  }

  if (candidate.candidateReportDetails.timeDetails.psychometricAnalysis) {
    timeLeft +=
      candidate.candidateReportDetails.timeDetails.psychometricAnalysis
        .timeLeft || 0;
    timeAllocated +=
      candidate.candidateReportDetails.timeDetails.psychometricAnalysis
        .allocatedTime;
  }

  const candidateScorePercentage =
    candidate.candidateReportDetails.scorePercentage;
  const timeTakenByCandidate = timeAllocated - Math.round(timeLeft / 60);
  const timePercentage = Math.round(
    (timeTakenByCandidate / timeAllocated) * 100
  );

  // checking if both the sections are present and calculating recommendation percentage based on that
  if (
    candidate.candidateReportDetails.timeDetails.psychometricAnalysis &&
    candidate.candidateReportDetails.timeDetails.skillsAnalysis
  ) {
    const strongSkillsPercentage = Math.round(
      (strongSkills.length / generalSkills.length) * 100
    );
    let psychometricMarksScored = 0;
    let psychometricTotalMarks = 0;
    let psychometricSkillPercentage = 0;
    if (
      candidate.candidateReportDetails.selectedAnswers.psychometricSkills[0]
    ) {
      psychometricMarksScored =
        candidate.candidateReportDetails.selectedAnswers.psychometricSkills[0]
          .marksScored;
      psychometricTotalMarks =
        candidate.candidateReportDetails.selectedAnswers.psychometricSkills[0]
          .totalMarks;
      psychometricSkillPercentage = Math.round(
        (psychometricMarksScored / psychometricTotalMarks) * 100
      );
    }

    skillsPercentage = Math.round(0.3 * strongSkillsPercentage);
    scorePercentage = Math.round(0.5 * candidateScorePercentage);
    psychometricScorePercentage = Math.round(0.2 * psychometricSkillPercentage);
    recommendedPercentage =
      scorePercentage + psychometricScorePercentage + skillsPercentage;
  } else if (candidate.candidateReportDetails.timeDetails.skillsAnalysis) {
    const strongSkillsPercentage = Math.round(
      (strongSkills.length / generalSkills.length) * 100
    );
    skillsPercentage = Math.round(0.3 * strongSkillsPercentage);
    scorePercentage = Math.round(0.7 * candidateScorePercentage);
    recommendedPercentage = scorePercentage + skillsPercentage;
  } else {
    const psychometricMarksScored =
      candidate.candidateReportDetails.selectedAnswers.psychometricSkills[0]
        .marksScored || 0;
    const psychometricTotalMarks =
      candidate.candidateReportDetails.selectedAnswers.psychometricSkills[0]
        .totalMarks || 1;
    recommendedPercentage = Math.round(
      (psychometricMarksScored / psychometricTotalMarks) * 100
    );
  }

  if (recommendedPercentage < 40 || candidate.resultStatus === 'cheated') {
    candidateSignalStatus = (
      <span className="stop_text_color signal_badge">Not Go Ahead</span>
    );
    recommendationSignalClass = [
      'signal_rnd_shape stop_signal stop_signal_clr',
      'signal_rnd_shape watch_signal',
      'signal_rnd_shape go_signal',
    ];
  } else if (recommendedPercentage <= 70) {
    candidateSignalStatus = (
      <span className="watch_text_color signal_badge">
        Proceed With Caution
      </span>
    );
    recommendationSignalClass = [
      'signal_rnd_shape stop_signal',
      'signal_rnd_shape watch_signal watch_signal_clr',
      'signal_rnd_shape go_signal',
    ];
  } else {
    candidateSignalStatus = (
      <span className="goahead_text_color signal_badge">Go Ahead</span>
    );
    recommendationSignalClass = [
      'signal_rnd_shape stop_signal',
      'signal_rnd_shape watch_signal',
      'signal_rnd_shape go_signal go_signal_clr',
    ];
  }
  if (candidate.candidateReportDetails.selectedAnswers.skills.length > 0)
    pieChartData = candidate.candidateReportDetails.selectedAnswers.skills.map(
      skill => ({
        name: skill.skill.skillName,
        scoredPercentage: skill.scoredPercentage,
      })
    );
  else
    pieChartData = [
      { name: 'skill-1', scoredPercentage: 50, color: 'rgb(255 146 146)' },
      { name: 'skill-2', scoredPercentage: 50, color: '#8eff8e' },
    ];

  return (
    <div className="cand_review_section">
      <div className="chart_section">
        <div className="scoremeter">
          <PieCharts
            innerRadius={20}
            width={200}
            height={190}
            data={pieChartData}
            dataKey="scoredPercentage"
          />
        </div>
        {candidate.candidateReportDetails.selectedAnswers.skills.length ===
          0 && (
          <div className="chart_note">Note: This is a sample skill chart</div>
        )}
      </div>
      <div className="recommended_review_section">
        <p className="skill_legend_recommend_text">
          SkillRobo recommends you to
          {candidateSignalStatus}
          with
          <span
            style={{
              color: '#793ef0',
            }}
          >
            {candidate.candidateReportDetails.additionalDetails[0].value}
          </span>
        </p>
        <div className="skill_traffic_dsn_sec">
          <div className="skill_legend">
            <div className="report_traffic_display_sec">
              <div className="report_signal_body">
                {recommendationSignalClass.map(className => (
                  <div className={className} key={className} />
                ))}
              </div>
            </div>
          </div>
          <CandidateSkillReview
            generalSkills={generalSkills}
            strongSkills={strongSkills}
            candidate={candidate}
            improvementSkills={improvementSkills}
            candidateScorePercentage={candidateScorePercentage}
            timePercentage={timePercentage}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateReviewDetails;
