import React, { useEffect, useContext, useState } from 'react';
import './OnBoard.css';
import AuthContext from '../../shared/contexts/AuthContext';
import { useUserAPI } from '../../shared/hooks/useUserAPI';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import OnBoardStep1 from '../Components/OnBoardStep1';
import OnBoardStep2 from '../Components/OnBoardStep2';
import SkillRoboWhiteLogo from '../../shared/components/UIElements/SkillRoboWhiteLogo';

const OnBoard = () => {
  const auth = useContext(AuthContext);
  const { updateUserAdditionalDetails, error } = useUserAPI();
  const [secondStep, setSecondStep] = useState(false);
  const [openSnackbar] = useSnackbar();
  const uData = JSON.parse(auth.userData);

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onSubmit = async data => {
    try {
      await updateUserAdditionalDetails(auth.userId, uData.userFullName, {
        ...data,
        decidingTimeline: data.decidingTimeline.value,
        position: data.position.value,
        openPositions: data.openPositions.value,
        testingDomain: data.testingDomain.map(val => val.value).join(','),
      });
      // country dispatch event for data analysis
      let userCountryEvent = new CustomEvent('userCountryEvent', {
        detail: {
          data: {
            country: data.country,
          },
        },
      });

      document.dispatchEvent(userCountryEvent);
      setSecondStep(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="addtnl_details_container">
      <div className="col_left getstrted">
        <div className="login-text getstrt_hdr">
          <SkillRoboWhiteLogo />
          <div className="get_strteddiv">
            <div
              className={
                secondStep ? 'getsrt_notxtdiv' : 'getsrt_notxtdiv active'
              }
            >
              <span className="getsrt_nospan"> 01 </span>
              <span className="getstrt_textspan">
                To get started, setup your organization details
              </span>
            </div>
            <div
              className={
                secondStep ? 'getsrt_notxtdiv active' : 'getsrt_notxtdiv'
              }
            >
              <span className="getsrt_nospan"> 02 </span>
              <span className="getstrt_textspan">
                Select a test from our test library or create your own custom
                test
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col_right">
        {secondStep ? (
          <OnBoardStep2 setOnBoardDone={auth.setIsAdditionalDetailsFilled} />
        ) : (
          <OnBoardStep1 onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default OnBoard;
