import React from 'react';
import { Link } from 'react-router-dom';
import BookAtlasIcon from '../../shared/components/UIElements/AtlasBookIcon';
import FolderPlus from '../../shared/components/UIElements/FolderPlus';

const OnBoardStep2 = ({ setOnBoardDone }) => {
  return (
    <div className="getstrted_rightdiv">
      <p>How would you like to get started with SkillRobo?</p>
      <div className="get_btndiv">
        <Link
          className="getbtn_ispan"
          to="/test-library"
          onClick={setOnBoardDone}
        >
          <BookAtlasIcon className="icon-list_workflow" />
          <span className="getbtn_textspan">Import Test</span>
        </Link>
        <Link className="getbtn_ispan" to="/tests/new" onClick={setOnBoardDone}>
          <FolderPlus />
          <span className="getbtn_textspan">Create New Test</span>
        </Link>
      </div>
    </div>
  );
};

export default OnBoardStep2;
