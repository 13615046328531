import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import './Charts.css';
import { getColorCodeByName } from '../../utils/Utils';

export const defaultMargin = {
  top: 50,
  right: 50,
  bottom: 50,
  left: 50,
};
export const defaultOuterRadius = 80;
export const defaultInnerRadius = 10;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const PieCharts = props => {
  return (
    <div id="pie-container" className="chart">
      <ResponsiveContainer>
        <PieChart width={props.width} height={props.height}>
          <Pie
            data={props.data}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={3}
            dataKey={props.dataKey}
            style={{ cursor: 'pointer' }}
          >
            {props.data.map(entry => (
              <Cell
                key={`cell-${entry.name}`}
                fill={entry.color || getColorCodeByName(entry.name)}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            formatter={value => {
              return (
                <span style={{ fontSize: 12, fontWeight: 400 }}>{value}</span>
              );
            }}
            iconSize={12}
            iconType="circle"
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieCharts;
