import React from 'react';

const CandidateSummaryTopDetails = ({ PoweredByLogo, clientLogo }) => (
  <div className="top_logo_sec">
    <div className="report_logo_container">
      <div className="client_logo_sec">
        <img
          src={clientLogo}
          className="report_client_logo"
          alt="report_client_logo"
        />
      </div>
      <p className="asses_report_top_text"> Assessment Report</p>
      <div className="powered_logo_sec">
        <PoweredByLogo />
      </div>
    </div>
  </div>
);

export default CandidateSummaryTopDetails;
