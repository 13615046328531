/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordian = ({ content, title, containerClass, defaultOpen }) => {
  const onClickShowContent = () => {
    const accordianElem = document.getElementById(containerClass);
    const contentElem = accordianElem.children[1];
    const icnElem = accordianElem.children[0].children[1];
    if (contentElem.classList.contains('open')) {
      icnElem.classList.remove('down');
      contentElem.classList.remove('open');
    } else {
      contentElem.classList.add('open');
      icnElem.classList.add('down');
    }
  };
  return (
    <div className="accordian_cont" id={containerClass}>
      <div
        className="accordian-title_cont"
        role="button"
        tabIndex={0}
        onClick={onClickShowContent}
      >
        <span className="accordian-title">{title}</span>
        <div className={`accordian-icn ${defaultOpen && 'down'}`}>
          <FontAwesomeIcon
            icon={['fas', 'chevron-down']}
            className="icon-chevron-down1"
          />
        </div>
      </div>
      <div className={`accordian-content ${defaultOpen && 'open'}`}>
        {content}
      </div>
    </div>
  );
};

export default Accordian;
