import { useCallback, useState } from 'react';
import useUtils from './useUtils';

const useLoginContextData = () => {
  const { getBool } = useUtils();

  const loggedin = localStorage.getItem('isLoggedIn')
    ? localStorage.getItem('isLoggedIn')
    : false;
  const ud =
    localStorage.getItem('user') !== undefined
      ? localStorage.getItem('user')
      : { userFullName: '', profilePic: '', userEmail: '', emailTemplates: {} };
  const id = localStorage.getItem('uid') ? localStorage.getItem('uid') : '';
  const masAd = localStorage.getItem('masAd')
    ? localStorage.getItem('masAd')
    : false;
  const masContentAd = localStorage.getItem('masContentAd')
    ? localStorage.getItem('masContentAd')
    : false;
  const uRole = localStorage.getItem('uRole')
    ? localStorage.getItem('uRole')
    : '';
  const client = localStorage.getItem('clientId')
    ? localStorage.getItem('clientId')
    : {};

  const additionalDetails = localStorage.getItem('isAdditionalDetails')
    ? JSON.parse(localStorage.getItem('isAdditionalDetails'))
    : true;

  const [isLoggedIn, setIsLoggedIn] = useState(loggedin);
  const [reload, setreload] = useState(false);
  const [isAdditionalDetails, setIsAdditionalDetails] =
    useState(additionalDetails);
  const [userId, setUserId] = useState(id);
  const [userData, setUserData] = useState(ud);
  const [clientId, setClientId] = useState(client);
  const [clientInfo, setClientInfo] = useState({});
  const [isMasAdmin, setIsMasAdmin] = useState(getBool(masAd));
  const [isMasContentAd, setIsMasContentAd] = useState(getBool(masContentAd));
  const [userRole, setUserRole] = useState(uRole);
  const [isMFAEnable, setMFAEnable] = useState(false);

  const loginTimeDetails = () => {
    const currentDateTime = new Date();
    const updateDateTime = new Date();
    const expiredDateTime = new Date(
      updateDateTime.setHours(updateDateTime.getHours() + 23)
    );
    const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
    const expireTimeStamp = Math.floor(expiredDateTime.getTime() / 1000);
    const initialLoginState = {
      loginTime: currentTimestamp,
      expirationTime: expireTimeStamp,
    };
    localStorage.setItem(
      'loginDetails',
      btoa(JSON.stringify(initialLoginState))
    );
  };

  const login = useCallback(
    (
      uid,
      userName,
      pic,
      email,
      logo,
      aToken,
      role,
      clientData,
      masAdmin,
      isAdditionalDetailsFilled,
      emailSettings,
      emailTemplates,
      clientDetails,
      isDelegatedUser = false,
      masContentAdmin
    ) => {
      const uData = JSON.stringify({
        userFullName: userName,
        profilePic: pic,
        userEmail: email,
        logo,
        emailSettings,
        emailTemplates,
      });
      setIsAdditionalDetails(isAdditionalDetailsFilled);
      setUserData(uData);
      setClientId(clientData);
      setUserId(uid);
      setIsMasAdmin(masAdmin || false);
      setIsMasContentAd(masContentAdmin || false);
      setUserRole(role);
      setIsLoggedIn(true);
      setClientInfo(clientDetails);

      localStorage.setItem('isAdditionalDetails', isAdditionalDetailsFilled);
      localStorage.setItem('user', uData);
      localStorage.setItem('clientId', clientData);
      localStorage.setItem('uid', uid);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('token', aToken);
      localStorage.setItem('uRole', role);
      localStorage.setItem('masAd', masAdmin);
      localStorage.setItem('masContentAd', masContentAdmin);
      localStorage.setItem('clientInfo', JSON.stringify(clientDetails));
      localStorage.setItem('isDelegatedUser', isDelegatedUser);
      loginTimeDetails();
    },
    []
  );

  const setMFAToken = useCallback(mfaToken => {
    localStorage.setItem('token', mfaToken);
    setMFAEnable(true);
  }, []);

  const setUserDetails = useCallback(uData => {
    setUserData(uData);
    localStorage.setItem('user', uData);
  }, []);

  const setIsAdditionalDetailsFilled = useCallback(() => {
    setIsAdditionalDetails(true);
    localStorage.setItem('isAdditionalDetails', true);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUserId(null);
    setClientId(null);
    setUserRole(null);
    setUserData({});
    setMFAEnable(false);
    // logout dispatch event for data analysis
    let logoutEvent = new CustomEvent('logoutEvent', {
      detail: {
        data: {
          userId: localStorage.getItem('uid'),
          role: localStorage.getItem('uRole'),
        },
      },
    });

    document.dispatchEvent(logoutEvent);
    localStorage.clear();
  }, []);

  return {
    isLoggedIn,
    userId,
    userData,
    clientId,
    clientInfo,
    userRole,
    isAdditionalDetails,
    isMasAdmin,
    isMasContentAd,
    setUserDetails,
    setIsAdditionalDetailsFilled,
    login,
    logout,
    reload,
    setreload,
    setMFAToken,
    isMFAEnable,
  };
};

export default useLoginContextData;
