import React from 'react';
import ConfirmationDialogContext from '../../contexts/ConfirmationDialogContext';
import ConfirmationDialog from './ConfirmationDialog';

const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({
    title,
    message,
    titleNote,
    actionCallback,
    confirmText,
    cancelText,
    isSubmit,
    containerClassName,
    helpContent,
    logo,
  }) => {
    setDialogOpen(true);
    setDialogConfig({
      title,
      message,
      titleNote,
      actionCallback,
      confirmText,
      cancelText,
      isSubmit,
      containerClassName,
      helpContent,
      logo,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{ dialogOpen, openDialog, resetDialog }}
    >
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        titleNote={dialogConfig?.titleNote}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        containerClassName={dialogConfig?.containerClassName}
        isSubmit={dialogConfig?.isSubmit}
        confirmText={dialogConfig?.confirmText}
        cancelText={dialogConfig?.cancelText}
        helpContent={dialogConfig?.helpContent}
        logo={dialogConfig.logo}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogProvider;
