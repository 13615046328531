import { useCallback } from 'react';
import { useHttpClient } from './useHttpClient';

const useContentAdminAPI = () => {
  const { sendRequest, error } = useHttpClient();

  const createContenAdmin = useCallback(async adminDetails => {
    const resp = await sendRequest(
      'users/createContentAdmin',
      'POST',
      adminDetails
    );
    return resp;
  }, []);

  const getContentAdminList = useCallback(async (search = '') => {
    const resp = await sendRequest(
      `users/getContentAdminList?search=${search}`,
      'GET'
    );
    return resp;
  }, []);

  const validateResetPasswordToken = useCallback(async token => {
    const response = await sendRequest(
      `content-admin/reset-password/${token}`,
      'GET',
      null,
      {},
      true
    );
    return response;
  }, []);

  const resetPassword = useCallback(
    async (adminId, passwordToken, newPassword) => {
      await sendRequest(
        'content-admin/updatePassword',
        'POST',
        JSON.stringify({
          newPassword: newPassword,
          adminId: adminId,
          passwordToken: passwordToken,
        }),
        {
          'Content-Type': 'application/json',
        },
        true
      );
    },
    []
  );

  const assignAdmin = useCallback(async data => {
    const response = await sendRequest(
      'content-admin/assign',
      'POST',
      JSON.stringify(data),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const updateContentAdminStatus = useCallback(async data => {
    const response = await sendRequest(
      'content-admin/updateContentAdminStatus',
      'PUT',
      JSON.stringify(data),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const updateContentAdmin = useCallback(async data => {
    const response = await sendRequest(
      `content-admin/updateContentAdmin/`,
      'PATCH',
      JSON.stringify(data),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const updateContentAdminProfile = useCallback(async (data, contenAdminId) => {
    const response = await sendRequest(
      `content-admin/updateProfile/${contenAdminId}`,
      'PATCH',
      data
    );
    return response;
  }, []);

  return {
    error,
    createContenAdmin,
    getContentAdminList,
    validateResetPasswordToken,
    resetPassword,
    assignAdmin,
    updateContentAdminStatus,
    updateContentAdmin,
    updateContentAdminProfile,
  };
};

export default useContentAdminAPI;
