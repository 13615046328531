import { useState, useCallback, useEffect, useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import LoaderContext from '../contexts/LoaderContext';
import StateProviderContext from '../contexts/StateProviderContext';

export const useHttpClient = () => {
  const auth = useContext(AuthContext);
  const { showLoader } = useContext(LoaderContext);
  const { activeHttpRequests } = useContext(StateProviderContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  // const activeHttpRequests = useRef([]);
  // commented on 06/10/2023 due to handling multiple request

  const sendRequest = useCallback(
    async (
      url,
      method = 'GET',
      body = null,
      headers = {},
      publicRequest,
      isLoaderEnable = true
    ) => {
      setIsLoading(true);
      if (isLoaderEnable) {
        showLoader(true);
      }
      setError('');
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);
      const token =
        localStorage.getItem('token') !== undefined
          ? localStorage.getItem('token')
          : '';
      const defaultHeader = {
        Authorization: `Bearer ${token}`,
      };
      const headerValues = publicRequest
        ? { ...headers }
        : { ...headers, ...defaultHeader };
      //   const fetchHeader = {
      //     Accept: headers.Accept && 'application/json',
      //     'Content-Type':
      //       headers['Content-Type'] && 'application/json;charset=UTF-8',
      //   };
      try {
        if (typeof navigator !== 'undefined' && !navigator.onLine) {
          throw new Error(
            'Network error. Please check your internet connection.'
          );
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
          method,
          body,
          headers: headerValues,
          signal: httpAbortCtrl.signal,
        });
        const responseData = await response.json();

        // Remove this abort controller from the array if the request is completed
        if (
          activeHttpRequests &&
          activeHttpRequests.current &&
          activeHttpRequests.current.length > 0
        ) {
          activeHttpRequests.current = activeHttpRequests.current.filter(
            reqCtrl => reqCtrl !== httpAbortCtrl
          );
        }

        if (!response.ok) {
          if (responseData.message.toLowerCase() === 'authentication failed.') {
            auth.logout();
          }
          setError(responseData.message);
          throw new Error(responseData.message);
        }
        if (
          activeHttpRequests.current &&
          activeHttpRequests.current.length == 0
        ) {
          setIsLoading(false);
          showLoader(false);
        }
        return responseData;
      } catch (err) {
        if (
          activeHttpRequests &&
          activeHttpRequests.current &&
          activeHttpRequests.current.length > 0
        ) {
          activeHttpRequests.current = activeHttpRequests.current.filter(
            reqCtrl => reqCtrl !== httpAbortCtrl
          );
        }
        console.log(err);
        setError(err.message);
        setIsLoading(false);
        showLoader(false);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  // Clean up function to remove abort controllers
  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (activeHttpRequests && activeHttpRequests.current) {
        activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
      }
    },
    []
  );

  return { isLoading, error, sendRequest, clearError };
};
