import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ResetPassword.css';
import undraw_safe_bnk7 from '../../shared/images/undraw_safe_bnk7.png';
import undraw_security_o890 from '../../shared/images/undraw_security_o890.png';
import Logo from '../../shared/components/UIElements/Logo';
import ResetPasswordConfirmPassword from '../Components/ResetPasswordConfirmPassword';
import ResetPasswordEmail from '../Components/ResetPasswordEmail';
import Form from '../../shared/components/FormElements/Form';
import { useUserAPI } from '../../shared/hooks/useUserAPI';
import useSnackbar from '../../shared/hooks/SnackBarHook';

const ResetPassword = () => {
  const { token } = useParams();
  const [userId, setUserId] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const {
    error,
    resetPassword,
    resetPasswordSubmitEmail,
    validateResetPasswordToken,
  } = useUserAPI();
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    if (token) {
      const validate = async () => {
        try {
          const user = await validateResetPasswordToken(token);
          setUserId(user.userId);
        } catch (err) {
          console.error(err);
        }
      };
      validate();
    }
  }, []);

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onSubmit = data => {
    if (token) {
      const setNewPassword = async () => {
        try {
          await resetPassword(userId, token, data.newPassword);
          openSnackbar('Password updated successfully.', 'success');
          window.location.href = '/login';
        } catch (err) {
          console.error(err);
        }
      };
      if (userId) setNewPassword();
    } else {
      const sendEmail = async () => {
        try {
          await resetPasswordSubmitEmail(data.email);
          setEmailSent(true);
        } catch (err) {
          console.error(err);
        }
      };
      sendEmail();
    }
  };

  return (
    <div className="can_login_container">
      <div className="can_login_body">
        <div className="can_login_first">
          <div className="reset_pass logo_container">
            <Logo />
          </div>
          {!emailSent && (
            <div className="can_login_main_img">
              <img
                src={token ? undraw_safe_bnk7 : undraw_security_o890}
                alt="reset password page"
              />
            </div>
          )}
        </div>
        <Form onSubmit={onSubmit}>
          {token ? (
            <ResetPasswordConfirmPassword />
          ) : (
            <ResetPasswordEmail emailSent={emailSent} />
          )}
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
