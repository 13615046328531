import { useCallback, useContext } from 'react';
import { useHttpClient } from './useHttpClient';
import StateProviderContext from '../contexts/StateProviderContext';
import AuthContext from '../contexts/AuthContext';

export const useTestsAPI = () => {
  const auth = useContext(AuthContext);
  const userLoginId = useContext(StateProviderContext);
  const userId = userLoginId.userId || localStorage.getItem('uid');
  const { sendRequest, error } = useHttpClient();

  const getTestById = useCallback(async testId => {
    const response = await sendRequest(`tests/${testId}`, 'GET');
    return response;
  }, []);

  const getTestByIdWithCandidates = useCallback(async testId => {
    const response = await sendRequest(`tests/candidates/${testId}`, 'GET');
    return response;
  }, []);

  const getcandidateTestConfigData = useCallback(async testId => {
    const response = await sendRequest(
      `tests/candidate-details-config/${testId}`,
      'GET'
    );
    return response;
  }, []);

  const generateTestPublicURL = useCallback(async testId => {
    const response = await sendRequest(`tests/public-url/${testId}`, 'PATCH');
    return response.publicURLToken;
  }, []);

  const getTestsByUserId = useCallback(async (loadingStatus = true, data) => {
    const response = await sendRequest(
      `tests/user/${auth.userId || userId}`,
      'GET',
      null,
      null,
      null,
      loadingStatus
    );
    return response;
  }, []);

  const getDashboardTestList = useCallback(async () => {
    const response = await sendRequest(`dashboard/${auth.userId}`, 'GET');
    return response;
  }, []);

  const updateTest = useCallback(async (testId, data) => {
    const endDate = new Date(data.endDate);
    endDate.setHours(23, 59, 59);
    const response = await sendRequest(
      `tests/${testId}`,
      'PATCH',
      JSON.stringify({
        testName: data.testName,
        description: data.description,
        instruction: data.instruction,
        startDate: data.startDate,
        endDate,
        duration: data.duration,
        passPercentage: data.passPercentage,
        isWebcamEnabled: data.isWebcamEnabled,
      }),
      { 'Content-Type': 'application/json' }
    );
    return response.test;
  }, []);

  const insertTest = useCallback(async data => {
    const endDate = new Date(data.endDate);
    endDate.setHours(23, 59, 59);
    const response = await sendRequest(
      'tests',
      'POST',
      JSON.stringify({
        testName: data.testName,
        description: data.description,
        instruction: data.instruction,
        startDate: data.startDate,
        endDate,
        duration: data.duration,
        passPercentage: data.passPercentage,
        isWebcamEnabled: data.isWebcamEnabled,
        creator: auth.userId,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const updateCandidateConfig = useCallback(async (data, tid) => {
    const response = await sendRequest(
      `tests/candidate-details-config/${tid}`,
      'PATCH',
      JSON.stringify({
        candidateDetailsConfig: data,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return response;
  }, []);

  const candidatePublicUrlEnable = useCallback(async (URLToken, testId) => {
    const response = await sendRequest(
      `tests/public-url/${testId}/${URLToken}/enable`,
      'PATCH'
    );
    return response;
  });
  const candidatePublicUrlDisable = useCallback(async (URLToken, testId) => {
    const response = await sendRequest(
      `tests/public-url/${testId}/${URLToken}`,
      'DELETE'
    );
    return response;
  });

  return {
    getTestById,
    getTestsByUserId,
    insertTest,
    updateTest,
    getDashboardTestList,
    generateTestPublicURL,
    getcandidateTestConfigData,
    updateCandidateConfig,
    candidatePublicUrlEnable,
    candidatePublicUrlDisable,
    getTestByIdWithCandidates,
    error,
  };
};
