import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserHelpContext from '../../contexts/UserHelpContext';
import PenIcon from '../UIElements/PenIcon/PenIcon';
import AddNotesIcon from '../UIElements/AddNotesIcon/AddNotesIcon';
import AddCandidateIcon from '../UIElements/AddCandidateIcon';
import ReportIcon from '../UIElements/ReportIcon';
import CloseIcon from '../UIElements/CloseIcon/CloseIcon';
import CheckedIcon from '../UIElements/CheckedIcon';
import UsersIcon from '../UIElements/UsersIcon/UsersIcon';
import SkillRoboIcon from '../../images/SkillRobo Icon.png';
import './HelpBot.css';

const HelpBot = () => {
  const [showBot, setShowBot] = useState(false);
  const { stage } = useContext(UserHelpContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setShowBot(false);
  }, [location]);

  return (
    <div className={showBot ? 'helpbot_container2' : 'helpbot_container1'}>
      <div className="helpbot_todolist">
        <span className="helpbot_title">
          <span>To do</span>
        </span>

        {/* create or import test */}
        <span className={stage > 1 ? 'helpbot_list_completed' : 'helpbot_list'}>
          {stage > 1 ? (
            <CheckedIcon className="helpbot_completed_icon" />
          ) : (
            <PenIcon className="helpbot_icon" />
          )}{' '}
          <span
            onClick={() => {
              navigate('/tests/new');
            }}
            className="helpbot_sublist"
          >
            Create
          </span>{' '}
          or{' '}
          <span
            className="helpbot_sublist"
            onClick={() => {
              navigate('/test-library');
            }}
          >
            Import
          </span>{' '}
          Test
        </span>

        {/* create or import skill */}
        <span className={stage > 2 ? 'helpbot_list_completed' : 'helpbot_list'}>
          {' '}
          {stage > 2 ? (
            <CheckedIcon className="helpbot_completed_icon" />
          ) : (
            <AddNotesIcon className="helpbot_icon" />
          )}{' '}
          <span
            onClick={() => {
              navigate('/skills/new');
            }}
            className="helpbot_sublist"
          >
            Create
          </span>{' '}
          or{' '}
          <span
            className="helpbot_sublist"
            onClick={() => {
              navigate('/skill-library');
            }}
          >
            Import
          </span>{' '}
          Skill
        </span>

        {/* invite candidate */}
        <span className={stage > 3 ? 'helpbot_list_completed' : 'helpbot_list'}>
          {' '}
          {stage > 3 ? (
            <CheckedIcon className="helpbot_completed_icon" />
          ) : (
            <AddCandidateIcon className="helpbot_icon" />
          )}{' '}
          <span
            className="helpbot_sublist"
            onClick={() => {
              navigate('/invite');
            }}
          >
            Invite Candidate
          </span>
        </span>

        {/* view candidates */}
        <span className={stage > 4 ? 'helpbot_list_completed' : 'helpbot_list'}>
          {' '}
          {stage > 4 ? (
            <CheckedIcon className="helpbot_completed_icon" />
          ) : (
            <ReportIcon className="helpbot_icon" />
          )}{' '}
          <span
            className="helpbot_sublist"
            onClick={() => {
              navigate('/candidates');
            }}
          >
            Candidate Reports
          </span>
        </span>
        <span className="helpbot_arrow_bottom" />
      </div>
      <div
        className="helpbot_image_container"
        onClick={() => {
          setShowBot(!showBot);
        }}
      >
        {showBot ? (
          <CloseIcon className="helpbot_close_icon" />
        ) : (
          <img src={SkillRoboIcon} className="helpbot_image" alt="helpbot" />
        )}
      </div>
    </div>
  );
};

export default HelpBot;
