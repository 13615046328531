import React, { useEffect, useRef, forwardRef } from 'react';
import CloseSolidIcon from '../UIElements/CloseSolidIcon';
import InputError from '../UIElements/InputError';
import './TagInput.css';

const LabelText = ({ data, dataIndex, onChange }) => {
  return (
    <div className="tagDiv">
      <span>{data}</span>
      <span
        onClick={() => {
          onChange(dataIndex);
        }}
        className="tagDivClose"
      >
        <CloseSolidIcon className="tagDivCloseIcon" />
      </span>
    </div>
  );
};

const TagInput = forwardRef(
  (
    {
      label,
      id,
      validation,
      placeholder,
      optionaInp = false,
      tagData: {
        emailData: inputData = [],
        setEmailData: setInputData = () => {},
      } = {},
      error,
      ...field
    },
    ref
  ) => {
    const inputText = useRef();
    const placeholderText = useRef();

    function removeElem(data) {
      let datum = [...inputData];
      datum.splice(data, 1);
      setInputData(datum);
    }

    const resizeInput = () => {
      inputText.current.style.width = `${inputText.current.value.length + 2}ch`;
    };

    useEffect(() => {
      field.onChange(inputData.toString());
      inputText.current.value = '';
      resizeInput();
      if (inputData.length > 0) {
        placeholderText.current.style.display = 'none';
      } else {
        placeholderText.current.style.display = 'block';
      }
    }, [inputData]);

    return (
      <div>
        {label && (
          <>
            <span
              htmlFor={id}
              className={`cmn_form_label ${
                validation?.required && 'cmn_required'
              }`}
            >
              {label}
            </span>
            {optionaInp && (
              <span className={'cmn_optional'}>{`( optional )`}</span>
            )}
          </>
        )}
        <div
          onClick={() => {
            inputText.current.focus();
          }}
          className="tagInputCont"
        >
          <span ref={placeholderText} className="tagInpPlHolder">
            {placeholder}
          </span>
          {inputData.length > 0 ? (
            <>
              {inputData.map((data, index) => {
                return (
                  <LabelText
                    onChange={removeElem}
                    key={index}
                    dataIndex={index}
                    data={data}
                  />
                );
              })}
            </>
          ) : (
            <></>
          )}
          <input
            type="text"
            ref={inputText}
            onInput={e => {
              resizeInput();
              if (e.target.value || inputData.length > 0) {
                placeholderText.current.style.display = 'none';
              } else {
                placeholderText.current.style.display = 'block';
              }
            }}
            onChange={e => {
              if (inputData.length == 0 && e.target.value != '') {
                field.onChange(`${e.target.value}`.trim());
              } else if (inputData.length > 0 && e.target.value != '') {
                field.onChange(
                  `${inputData.toString()},${e.target.value}`.trim()
                );
              } else {
                field.onChange(inputData.toString());
              }
            }}
            onKeyDown={e => {
              e.persist();
              if (e.key === 'Enter' || e.key === 'Tab' || e.key == ',') {
                e.preventDefault();
                if (
                  e.target?.value.trim() &&
                  !inputData.includes(e.target?.value.trim())
                ) {
                  setInputData(prevstate => [
                    ...prevstate,
                    e.target.value.trim(),
                  ]);
                }

                if (inputData.includes(e.target.value.trim())) {
                  e.target.value = '';
                }
              }

              if (e.key === 'Backspace' && e.target.value == '') {
                let datum = [...inputData];
                datum.pop();
                setInputData(datum);
              }
            }}
          />
        </div>
        {error && <InputError errMsg={error} />}
      </div>
    );
  }
);

export default TagInput;
