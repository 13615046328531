import React from 'react';
import Button from '../../shared/components/FormElements/Button';
import '../../shared/components/Popup/popup.css';
import CandidatePopupQuestionCard from '../Component/CandidatePopupQuestionCard';
import './CandidatePopup.css';

const CandidateQuestionsPopup = props => {
  return (
    <div className="cmn_overlay">
      <div className="cmn_popup big_popup">
        <div className="cmn_pop_head cmn_dis_flx cmn_justify_spa_bwn cmn_al_cen">
          <h2> {props.skillDetails.name} - Questions and Answers</h2>
        </div>
        <div className="cmn_pop_content can_rep_height">
          {props.skillDetails.answers.map((val, index) => {
            return (
              <CandidatePopupQuestionCard
                key={val.id}
                index={index}
                question={val.question}
                answer={val.answer}
                answers={val.answers}
              />
            );
          })}
        </div>
        <div className="cmn_pop_footer">
          <div className="cmn_form_btn_container">
            <Button
              className="cmn_btn cmn_primary_txt cmn_secondary_btn cmn_pop_close"
              onClick={() => {
                props.closePopupToggle();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateQuestionsPopup;
