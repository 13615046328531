import { useState, useEffect, useContext, useCallback } from 'react';
import useConfirmationDialog from './useConfirmationDialog';

const useLoginContextData = () => {
  const userHelp = JSON.parse(
    localStorage.getItem('userHelp') || '{"isEnabled":false,"stage":0}'
  );
  const [isEnabled, setIsEnabled] = useState(userHelp.isEnabled);
  const [stage, setStage] = useState(userHelp.stage);
  const [countdown, setcountdown] = useState(30000); // countdown for help bot to open up
  const [pendingState, setPendingState] = useState(''); // if any pending like no questions created after skill creation, it enables pending state for that action
  const [isUserActive, setUserActive] = useState(true); // indicating user active or inactive status
  const { dialogOpen } = useConfirmationDialog();

  const [showHelp, setShowHelp] = useState(false);

  const changeHelpStatus = () => {
    setShowHelp(false);
  };

  // helpbot commented on 23/11/2023

  // useEffect(() => {
  //   let timeoutId;
  //   clearTimeout(timeoutId);
  //   if (!dialogOpen && isEnabled) {
  //     timeoutId = setTimeout(() => {
  //       setShowHelp(prevShowHelp => !prevShowHelp);
  //     }, countdown); // triggers help bot for every countdown seconds
  //   }
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [showHelp, countdown, dialogOpen]);

  // changes user's help bot stage
  const setUserStage = currentStage => {
    if (currentStage) {
      let currentUserHelp = JSON.parse(localStorage.getItem('userHelp'));
      currentUserHelp.stage = currentStage;
      localStorage.setItem('userHelp', JSON.stringify(currentUserHelp));
      setStage(currentStage);
    }
  };

  // enabling user's help bot
  const setHelpIsEnable = isHelpEnable => {
    if (isHelpEnable == true || isHelpEnable == false) {
      let currentUserHelp = JSON.parse(localStorage.getItem('userHelp'));
      currentUserHelp.isEnabled = isHelpEnable;
      localStorage.setItem('userHelp', JSON.stringify(currentUserHelp));
      setIsEnabled(isHelpEnable);
    }
  };

  const fetchUserStage = useCallback(async () => {
    try {
      let response = await fetch(
        `${window.REACT_APP_ANALYSIS_URL}/api/users/getuserstage`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        }
      );
      let results = response.json();
      return results;
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    isEnabled,
    stage,
    setIsEnabled,
    setStage,
    setUserStage,
    showHelp,
    setShowHelp,
    changeHelpStatus,
    isUserActive,
    setUserActive,
    setcountdown,
    setHelpIsEnable,
    pendingState,
    setPendingState,
    fetchUserStage,
  };
};

export default useLoginContextData;
