import { useCallback } from 'react';
import { useHttpClient } from './useHttpClient';

export const useUserAPI = () => {
  const { sendRequest, error } = useHttpClient();

  const verifyUser = useCallback(async token => {
    await sendRequest(`users/verify-email/${token}`, 'GET', null, {}, true);
  }, []);

  const requestAdditionalSkill = useCallback(async skillName => {
    await sendRequest(
      'users/additional-skill',
      'POST',
      JSON.stringify({
        skillName: skillName,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const requestAdditionalTest = useCallback(async testName => {
    await sendRequest(
      'users/additional-test',
      'POST',
      JSON.stringify({
        testName: testName,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const updateUserDetails = useCallback(async (uId, name, profilePic) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', profilePic || null);
    const resp = await sendRequest(`users/${uId}`, 'PATCH', formData, {});
    return resp;
  }, []);

  const updateEmailTemplates = useCallback(async (uId, data) => {
    await sendRequest(
      `users/email-templates/${uId}`,
      'PATCH',
      JSON.stringify({
        ...data,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const updateUserEmailSettings = useCallback(
    async (email, password, provider, uId) => {
      await sendRequest(
        `users/email-settings/${uId}`,
        'PATCH',
        JSON.stringify({
          email,
          password,
          provider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
    },
    []
  );

  const updateUserAdditionalDetails = useCallback(
    async (uId, userFullName, data) => {
      await sendRequest(
        `users/additional-details/${uId}`,
        'PATCH',
        JSON.stringify({
          ...data,
          name: userFullName,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
    },
    []
  );

  const updateLogo = useCallback(async (uId, logo) => {
    const formData = new FormData();
    formData.append('logo', logo);
    const resp = await sendRequest(
      `users/update-logo/${uId}`,
      'PATCH',
      formData,
      {}
    );
    return resp;
  }, []);

  const resetPasswordSubmitEmail = useCallback(async emailId => {
    await sendRequest(
      'users/reset-password',
      'POST',
      JSON.stringify({
        email: emailId,
      }),
      {
        'Content-Type': 'application/json',
      },
      true
    );
  }, []);

  const validateResetPasswordToken = useCallback(async token => {
    const response = await sendRequest(
      `users/reset-password/${token}`,
      'GET',
      null,
      {},
      true
    );
    return response;
  }, []);

  const changePassword = useCallback(async (userId, password) => {
    await sendRequest(
      `users/update-password/${userId}`,
      'PATCH',
      JSON.stringify({
        currentPassword: password.currentPassword,
        newPassword: password.newPassword,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const resetPassword = useCallback(
    async (userId, passwordToken, newPassword) => {
      await sendRequest(
        'users/new-password',
        'POST',
        JSON.stringify({
          newPassword: newPassword,
          userId: userId,
          passwordToken: passwordToken,
        }),
        {
          'Content-Type': 'application/json',
        },
        true
      );
    },
    []
  );

  const createUser = useCallback(async (name, email, role, isEmailAlerts) => {
    const resp = await sendRequest(
      'users/create',
      'POST',
      JSON.stringify({
        name,
        email,
        role,
        isEmailAlerts,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
    return resp;
  }, []);

  const updateUserRole = useCallback(async (userId, userRole) => {
    await sendRequest(
      `users/role/${userId}`,
      'PATCH',
      JSON.stringify({
        userRole,
      }),
      {
        'Content-Type': 'application/json',
      }
    );
  }, []);

  const updateUserInfo = useCallback(
    async (userId, userRole, status, isEmailAlerts) => {
      await sendRequest(
        `users/info/${userId}`,
        'PATCH',
        JSON.stringify({
          userRole,
          status,
          isEmailAlerts,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
    },
    []
  );

  const getUsers = useCallback(async () => {
    const response = await sendRequest(`users/`, 'GET');
    return response.users;
  }, []);

  const activateUser = useCallback(async uId => {
    await sendRequest(`users/activate/${uId}`, 'PATCH');
  }, []);

  const deactivateUser = useCallback(async uId => {
    await sendRequest(`users/deactivate/${uId}`, 'PATCH');
  }, []);

  const getIceServers = useCallback(async () => {
    const iceServers = await sendRequest('users/get-turnserver-config', 'GET');
    if (
      iceServers &&
      iceServers.iceServers &&
      iceServers.iceServers.length > 0
    ) {
      return iceServers.iceServers;
    }
    return [];
  }, []);

  const getAllUsers = useCallback(async () => {
    const usersData = await sendRequest('users/getall-users', 'GET');

    if (usersData && usersData.users && usersData.users.length > 0) {
      return usersData.users;
    }

    return [];
  }, []);

  const verifyMFALogin = useCallback(async mfaToken => {
    const usersData = await sendRequest(
      'users/verify-mfa',
      'POST',
      JSON.stringify({
        mfaToken,
      }),
      {
        'Content-Type': 'application/json',
      }
    );

    return usersData;
  }, []);

  const delegateUserLogin = useCallback(async data => {
    const usersData = await sendRequest(
      'users/delegated-user-login',
      'POST',
      JSON.stringify({
        email: data.useremail,
        password: data.password,
      }),
      {
        'Content-Type': 'application/json',
      }
    );

    return usersData;
  }, []);

  return {
    error,
    updateLogo,
    verifyUser,
    resetPasswordSubmitEmail,
    resetPassword,
    validateResetPasswordToken,
    updateUserDetails,
    updateUserAdditionalDetails,
    requestAdditionalSkill,
    requestAdditionalTest,
    changePassword,
    updateUserEmailSettings,
    getUsers,
    createUser,
    activateUser,
    updateUserRole,
    deactivateUser,
    updateEmailTemplates,
    updateUserInfo,
    getIceServers,
    getAllUsers,
    delegateUserLogin,
    verifyMFALogin,
  };
};
