import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const InfoIcon = props => {
  return (
    <ATag onClick={props.onClick} {...props}>
      <FontAwesomeIcon icon={['far', 'circle-info']} />
    </ATag>
  );
};

export default InfoIcon;
