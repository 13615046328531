import React, { useRef, useEffect } from 'react';

const CandidatePopupQuestionCard = ({ question, answer, index }) => {
  let marksScored = 0;
  const answersRef = useRef([]);
  const questionRef = useRef();
  const answerChoices =
    question.questionType === 'Multi answer selection'
      ? question.answers.multiChoice
      : question.answers.singleChoice;

  useEffect(() => {
    answerChoices.forEach((ans, ansIndex) => {
      if (answersRef.current[ansIndex])
        answersRef.current[ansIndex].innerHTML = ans.answer;
    });

    questionRef.current.innerHTML = question.question;
  }, []);

  // calculating marks scored in this question by candidate
  if (answer.single.answer && answer.single.mark) {
    marksScored = answer.single.mark;
  } else {
    answer.multi.forEach(val => {
      if (val.mark) {
        marksScored += val.mark;
      }
    });
  }

  return (
    <div className="ans_question_card">
      <div className="ans_ques_top">
        <div className="ans_ques_top_left">
          <div className="ans_qus_name">
            <div> Q{index + 1}.</div>
            <div ref={questionRef} />
          </div>
        </div>
        <div className="ans_qus_top_right">
          <div className="ans_mark_scored">Mark Scored: {marksScored}</div>
        </div>
      </div>
      <div className="ans_container">
        {(() => {
          switch (question.questionType) {
            case 'True or False selection':
              // checking if the candidate has answered the question
              if (answer.single.answer !== null) {
                // checking if the candidate answer is correct
                if (answer.single.mark)
                  return (
                    <>
                      <div className="ans_answer  can_crt_ans">
                        <div>{answer.single.answer}</div>
                        <div className="cmn_ans">Candidate answer</div>
                      </div>
                      <div className="ans_answer">
                        <div>
                          {answer.single.answer === 'true' ? 'False' : 'True'}
                        </div>
                      </div>
                    </>
                  );
                return (
                  <>
                    <div className="ans_answer  can_wrng_ans">
                      <div>
                        {answer.single.answer === 'true' ? 'True' : 'False'}
                      </div>
                      <div className=" cmn_ans">Wrong answer</div>
                    </div>
                    <div className="ans_answer  crt_ans">
                      <div>
                        {answer.single.answer === 'true' ? 'False' : 'True'}
                      </div>
                      <div className="cmn_ans">Correct answer</div>
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div className="ans_answer crt_ans">
                    <div>{question.answer === 'true' ? 'True' : 'False'}</div>
                    <div className="cmn_ans">Correct answer</div>
                  </div>
                  <div className="ans_answer">
                    <div>{question.answer === 'true' ? 'False' : 'True'}</div>
                  </div>
                </>
              );

            case 'Fill in the blank':
              // checking if the candidate has answered the question
              if (answer.single.answer !== null) {
                // checking if the candidate answer is correct
                if (answer.single.mark)
                  return (
                    <div className="ans_answer can_crt_ans">
                      <div>{question.answers.text}</div>
                      <div className="cmn_ans">Candidate answer</div>
                    </div>
                  );
                return (
                  <>
                    <div className="ans_answer crt_ans">
                      <div>{question.answers.text}</div>
                      <div className="cmn_ans">Correct answer</div>
                    </div>
                    <div className="ans_answer can_wrng_ans">
                      <div>{answer.single.answer}</div>
                      <div className="cmn_ans">Wrong answer</div>
                    </div>
                  </>
                );
              }
              return (
                <div className="ans_answer crt_ans">
                  <div>{question.answers.text}</div>
                  <div className="cmn_ans">Correct answer</div>
                </div>
              );
            default: {
              let answers = [];
              // checking if the candidate has answered the question
              if (answer.multi[0].multiAnswerId !== null) {
                answers = answerChoices.map((ans, ansIndex) => {
                  if (
                    answer.multi.some(
                      candAns => candAns.multiAnswerId === ans.id
                    )
                  ) {
                    if (ans.correctAnswer) {
                      return (
                        <div className="ans_answer can_crt_ans">
                          <div
                            ref={r => {
                              answersRef.current[ansIndex] = r;
                            }}
                          />
                          <div className="cmn_ans">Candidate answer</div>
                        </div>
                      );
                    }
                    return (
                      <div className="ans_answer can_wrng_ans">
                        <div
                          ref={r => {
                            answersRef.current[ansIndex] = r;
                          }}
                        />
                        <div className="cmn_ans">Wrong answer</div>
                      </div>
                    );
                  }
                  if (ans.correctAnswer)
                    return (
                      <div className="ans_answer crt_ans">
                        <div
                          ref={r => {
                            answersRef.current[ansIndex] = r;
                          }}
                        />
                        <div className="cmn_ans">Correct answer</div>
                      </div>
                    );
                  return (
                    <div className="ans_answer">
                      <div
                        ref={r => {
                          answersRef.current[ansIndex] = r;
                        }}
                      />
                    </div>
                  );
                });
              } else {
                answers = answerChoices.map((ans, ansIndex) => {
                  if (ans.correctAnswer) {
                    return (
                      <div className="ans_answer crt_ans">
                        <div
                          ref={r => {
                            answersRef.current[ansIndex] = r;
                          }}
                        />
                        <div className="cmn_ans">Correct answer</div>
                      </div>
                    );
                  }
                  return (
                    <div className="ans_answer">
                      <div
                        ref={r => {
                          answersRef.current[ansIndex] = r;
                        }}
                      />
                    </div>
                  );
                });
              }
              return <>{answers}</>;
            }
          }
        })()}
      </div>
    </div>
  );
};

export default CandidatePopupQuestionCard;
