import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NextIcon = ({ className }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={['fas', 'arrow-right']}
        className={`icon-arrow_forward1 ${className}`}
      />
    </div>
  );
};

export default NextIcon;
