import React, { useCallback, useState } from 'react';
import TopNavBar from './TopNavBar';
import SideNavBar from './SideNavBar';
import TestContext from '../../shared/contexts/TestContext';
import useComponentVisible from '../../shared/hooks/ComponentVisible';

const MainLayout = props => {
  const { ref, isComponentVisible, setisComponentVisible } =
    useComponentVisible(false);
  const testId =
    localStorage.getItem('testId') !== undefined
      ? localStorage.getItem('testId')
      : '';
  const testName =
    localStorage.getItem('testName') !== undefined
      ? localStorage.getItem('testName')
      : '';
  const skillId =
    localStorage.getItem('skillId') !== undefined
      ? localStorage.getItem('skillId')
      : '';
  const skillName =
    localStorage.getItem('skillName') !== undefined
      ? localStorage.getItem('skillName')
      : '';
  const [tId, setTId] = useState(testId);
  const [tName, setTName] = useState(testName);
  const [sId, setSId] = useState(skillId);
  const [sName, setSName] = useState(skillName);
  const setTestId = useCallback(tid => {
    setTId(tid);
    localStorage.setItem('testId', tid);
  }, []);
  const setTestName = useCallback(tname => {
    setTName(tname);
    localStorage.setItem('testName', tname);
  }, []);
  const setSkillId = useCallback(sid => {
    setSId(sid);
    localStorage.setItem('skillId', sid);
  }, []);
  const setSkillName = useCallback(sname => {
    setSName(sname);
    localStorage.setItem('skillName', sname);
  }, []);
  return (
    <>
      <TopNavBar
        mbl_menu={ref}
        setisComponentVisible={setisComponentVisible}
        isComponentVisible={isComponentVisible}
      />
      <div className="cmn_body_container">
        <SideNavBar isComponentVisible={isComponentVisible} />
        <TestContext.Provider
          value={{
            tId,
            tName,
            sId,
            sName,
            setTestId,
            setTestName,
            setSkillId,
            setSkillName,
          }}
        >
          <div className="cmn_work_area">{props.children}</div>
        </TestContext.Provider>
      </div>
    </>
  );
};

export default MainLayout;
