import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faUserGraduate,
  faMemoPad,
  faClipboardListCheck,
  faAward,
  faEnvelope,
  faCrown,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCircleQuestion,
  faCircleInfo,
  faUser as faLightUser,
  faCreditCard,
  faRightFromBracket,
  faFileExcel,
  faUserCheck,
  faUserClock,
  faXmark,
  faVideo,
  faSignalStream,
  faCodePullRequest,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChartLine,
  faFileLines,
  faBrain,
  faBooks,
  faUser,
  faThumbsUp,
  faThumbsDown,
  faUserPlus,
  faLayerPlus,
  faEye,
  faEyeSlash,
  faPen,
  faFilter,
  faArrowLeft,
  faArrowUp,
  faArrowRight,
  faFiles,
  faCaretUp,
  faCaretDown,
  faDownToLine,
  faLinkSimple,
  faChevronDown,
  faStar,
  faLockKeyhole,
  faEnvelope as faSolidEnvelope,
  faCamera,
  faCircleXmark,
  faCheck,
  faTrash,
  faCirclePlus,
  faPlus,
  faMinus,
  faFolderPlus,
  faCopy,
  faBookAtlas,
  faWandMagicSparkles,
  faPenClip,
  faNotesMedical,
  faXmark as faSolidXmark,
  faClipboard,
  faUsers,
  faBars,
  faLocationDot,
  faLaptopMobile,
  faChevronsLeft,
  faArrowsRotate,
  faChevronLeft,
  faChevronsRight,
  faCameraWeb,
  faUniversalAccess,
  faArrowTurnDownRight,
  faUserTie,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faUserGraduate,
  faChartLine,
  faFileLines,
  faBrain,
  faBooks,
  faUser,
  faThumbsUp,
  faThumbsDown,
  faUserPlus,
  faCircleQuestion,
  faCircleInfo,
  faLayerPlus,
  faEye,
  faEyeSlash,
  faPen,
  faLightUser,
  faCreditCard,
  faRightFromBracket,
  faFilter,
  faArrowLeft,
  faArrowUp,
  faMemoPad,
  faFileExcel,
  faFiles,
  faUserCheck,
  faClipboardListCheck,
  faUserClock,
  faAward,
  faCaretUp,
  faCaretDown,
  faDownToLine,
  faLinkSimple,
  faEnvelope,
  faChevronDown,
  faStar,
  faLockKeyhole,
  faSolidEnvelope,
  faCamera,
  faCircleXmark,
  faCheck,
  faTrash,
  faCirclePlus,
  faXmark,
  faPlus,
  faMinus,
  faFolderPlus,
  faCopy,
  faBookAtlas,
  faWandMagicSparkles,
  faPenClip,
  faNotesMedical,
  faSolidXmark,
  faClipboard,
  faUsers,
  faArrowRight,
  faBars,
  faVideo,
  faSignalStream,
  faLocationDot,
  faLaptopMobile,
  faChevronsLeft,
  faArrowsRotate,
  faChevronLeft,
  faChevronsRight,
  faCameraWeb,
  faUniversalAccess,
  faCrown,
  faArrowTurnDownRight,
  faUserTie,
  faUpload,
  faCodePullRequest
);
