import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import InputError from '../UIElements/InputError';

const SelectField = props => {
  const {
    id,
    name,
    label,
    defaultValue,
    validation,
    required,
    className,
    children,
    options,
    isMulti,
    ...rest
  } = props;
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (options?.length > 0) {
      if (typeof defaultValue == 'string') {
        const selected = options.filter(
          i => i.value.toLowerCase() === defaultValue.toLowerCase()
        );
        setValue(id, selected[0]);
      } else if (Array.isArray(defaultValue)) {
        const selected = options.filter(i => {
          return defaultValue.some(obj => obj.value == i.value);
        });
        setValue(id, selected);
      }
    }
  }, []);

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className={`cmn_form_label ${required && 'cmn_required'}`}
        >
          {label}
        </label>
      )}
      <div>
        <Controller
          name={name}
          id={id}
          className={`cmn_form_input ${className} ${
            errors[id] ? 'cmn_form_invalid_input' : ''
          }`}
          {...rest}
          defaultValue={defaultValue || ''}
          rules={validation || { required: required }}
          render={({ field }) => (
            <ReactSelect
              options={options}
              classNamePrefix="react-select"
              isMulti={isMulti}
              {...field}
            />
          )}
        />
        {children}
        {errors[id] && <InputError errMsg={errors[id].message} />}
      </div>
    </>
  );
};
export default SelectField;
