import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideNavBar = props => (
  <div
    className={`sidbr_container ${
      props.isComponentVisible ? 'mbl_active' : ''
    } cmn_box_shadow_x sidbar_mbl_vu`}
  >
    <div className="sidbar_mnu_container clr_bg_white">
      <ul>
        <li>
          <NavLink to="/skill-library" className="cmn_icon_txt">
            <FontAwesomeIcon icon={['fas', 'brain']} className="icon-sk_idea" />
            <span>Skill Library</span>
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
);

export default SideNavBar;
