import React from 'react';
import SkillRoboIcon from '../../images/SkillRobo Icon.png';
import Button from '../FormElements/Button';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({
  open,
  title,
  titleNote,
  message,
  onConfirm,
  onDismiss,
  containerClassName,
  confirmText = 'Yes',
  cancelText = 'No',
  isSubmit = true,
  helpContent,
  logo,
}) => {
  return (
    <>
      {open && (
        <div className="confirmation-wrapper">
          <div
            className={
              containerClassName
                ? `confirmation-container ${containerClassName}`
                : 'confirmation-container'
            }
          >
            <div className="confirmation-title">
              {logo && <img src={SkillRoboIcon} alt="SkillRobo Icon" />}
              <span>
                <h2>{title}</h2>
                {titleNote && <h5 className="title-note">{titleNote}</h5>}
              </span>
            </div>
            {helpContent ||
              (typeof message === 'string' ? (
                <>
                  <div className="message">{message}</div>
                  <div className="action">
                    <Button
                      type="button"
                      className="cmn_btn cmn_primary_txt cmn_secondary_btn"
                      onClick={onDismiss}
                    >
                      {cancelText}
                    </Button>
                    {isSubmit && (
                      <Button
                        type="button"
                        className="cmn_btn cmn_primary"
                        onClick={onConfirm}
                      >
                        {confirmText}
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                message
              ))}
          </div>
        </div>
      )}
    </>
  );
};
export default ConfirmationDialog;
