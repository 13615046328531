import React from 'react';
import Accordian from '../../shared/components/UIElements/Accordian';
import Stars from '../../shared/components/Stars/Stars';

const CandidateFeedback = ({ feedback }) => {
  const content = feedback ? (
    <div className="cmn_form_fields cmn_mgn_top">
      <div className="overall_rating">
        Rating:
        <span>
          <Stars feedbackRating={feedback.rating} />
        </span>
      </div>
      <div className="feedback_comment">
        Comments:
        <span>{feedback.comment || 'No comments'}</span>
      </div>
    </div>
  ) : (
    <div className="cmn_form_fields cmn_mgn_top no_feedback">
      <span>No feedback</span>
    </div>
  );
  return (
    <div className="feedback_section">
      <Accordian
        defaultOpen
        containerClass="feedback"
        content={content}
        title={
          <span className="sec_ttl">
            <div className="test_invite_container">
              <p className="report_detail_heading">Feedback</p>
            </div>
          </span>
        }
      />
    </div>
  );
};

export default CandidateFeedback;
