import React from 'react';
import Button from '../../shared/components/FormElements/Button';
import InputField from '../../shared/components/FormElements/InputField';
import { Email_Regex } from '../../shared/constants/AppConstants';
import Logo from '../../shared/components/UIElements/Logo';
import './login.css';
import Form from '../../shared/components/FormElements/Form';

const ForgotPwdForm = () => {
  const onPwdReset = data => {
    console.log(data);
  };
  return (
    <div className="login_body_container">
      <div className="login_body_center">
        <div className="login_top">
          <Logo />
        </div>
        <div className="login_form_container">
          <div className="login_sub_head">
            <span>
              Enter your email below and we will send you instructions on how to
              change your password.
            </span>
          </div>
          <Form onSubmit={onPwdReset}>
            <div className="cmn_form_input_container cmn_wi_full">
              <InputField
                id="email"
                name="email"
                type="email"
                label="Email ID"
                placeholder=""
                validation={{
                  required: 'Required',
                  pattern: {
                    value: Email_Regex,
                    message: 'Invalid email address',
                  },
                }}
              />
            </div>
            <Button
              pretext="Return to "
              buttontype="Link"
              to="/login"
              className="cmn_btn cmn_primary_txt cmn_pad_ze_fi"
            >
              Login
            </Button>
            <Button type="submit" className="cmn_btn cmn_primary cmn_btn_full">
              Send
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPwdForm;
