import React from 'react';
import ConfirmationDialogContext from '../contexts/ConfirmationDialogContext';

const useConfirmationDialog = () => {
  const { openDialog, resetDialog, dialogOpen } = React.useContext(
    ConfirmationDialogContext
  );

  const getConfirmation = ({ ...options }) =>
    new Promise(res => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation, resetDialog, dialogOpen };
};
export default useConfirmationDialog;
