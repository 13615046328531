import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from '../ATag';

const UsersIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon
        icon={['fas', 'users']}
        className={`icon-users ${props.className}`}
      />
    </ATag>
  );
};

export default UsersIcon;
