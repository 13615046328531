import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoaderElement from '../shared/components/Loader/LoaderElement';

const ContentAdminSkillLib = lazy(() =>
  import('../ContentAdmin/SkillsLibrary/Pages/ContentAdminSkillsLib')
);
const ViewQuestions = lazy(() =>
  import('../ContentAdmin/Questions/Pages/ViewQuestions')
);
const AddQuestions = lazy(() =>
  import('../ContentAdmin/Questions/Pages/AddQuestion')
);
const EditQuestion = lazy(() =>
  import('../ContentAdmin/Questions/Pages/EditQuestion')
);

const NewSkill = lazy(() =>
  import('../ContentAdmin/SkillsLibrary/Pages/NewSkill')
);

const ContentAdminRoute = () => {
  return (
    <Suspense fallback={<LoaderElement />}>
      <Routes>
        <Route exact path="/" element={<ContentAdminSkillLib />} />

        <Route path="/:sid/questions" element={<ViewQuestions />} />
        <Route exact path="/:sid/questions/new" element={<AddQuestions />} />
        <Route exact path="/:sid/questions/:qid" element={<EditQuestion />} />
        <Route exact path="/skill/new" element={<NewSkill />} />
        <Route
          exact
          path="/:sid/questions/version/:qvid"
          element={<EditQuestion />}
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default ContentAdminRoute;
