import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const CancelIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon icon={['far', 'xmark']} className="icon-cancel" />
    </ATag>
  );
};

export default CancelIcon;
