import React, { useEffect } from 'react';
import Form from '../../shared/components/FormElements/Form';
import InputField from '../../shared/components/FormElements/InputField';
import { useUserAPI } from '../../shared/hooks/useUserAPI';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import { Email_Regex } from '../../shared/constants/AppConstants';
import './UsersLogin.css';

const UsersLoginForm = ({ resetDialog, delegateLogin }) => {
  const { delegateUserLogin, error } = useUserAPI();
  const [openSnackbar] = useSnackbar();

  const login = async data => {
    try {
      let responseData = await delegateUserLogin(data);
      resetDialog();
      delegateLogin(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  return (
    <div>
      <Form onSubmit={login}>
        <div className="cmn_form_input_container">
          <InputField
            id="useremail"
            name="useremail"
            label="Enter email"
            placeholder="Enter email you want to connect"
            type="text"
            validation={{
              required: 'Required',
              pattern: {
                value: Email_Regex,
                message: 'Invalid email address',
              },
            }}
          />
        </div>

        <div className="cmn_form_input_container">
          <InputField
            id="password"
            name="password"
            type="password"
            label="Enter superadmin password"
            placeholder=""
            validation={{
              required: 'Required',
            }}
          />
        </div>
        <div className="delegate_button_container">
          <button type="button" className="cmn_btn" onClick={resetDialog}>
            Cancel
          </button>
          <button className="cmn_btn cmn_primary">Login</button>
        </div>
      </Form>
    </div>
  );
};

export default UsersLoginForm;
