import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckedIcon = props => (
  <FontAwesomeIcon
    icon={['fas', 'check']}
    className={`icon-check2 ${props.className || ''}`}
  />
);

export default CheckedIcon;
