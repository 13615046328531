import React from 'react';
import RibbonIcon from '../../shared/components/UIElements/RibbonIcon';

const CandidateSummaryDetails = ({ candidateData }) => (
  <div className="candidate_rep_det_sec border_right">
    {candidateData.isShortlisted ? (
      <p className="cand_rep_strong">
        <RibbonIcon />
        {candidateData.candidateReportDetails.additionalDetails[0].value}
      </p>
    ) : (
      <p className="cand_rep_strong">
        {candidateData.candidateReportDetails.additionalDetails[0].value}
      </p>
    )}

    <p>{candidateData.email}</p>
    {candidateData.candidateReportDetails.additionalDetails.map(detail =>
      detail.label == 'Resume' ? (
        <a
          key={detail.label}
          href={detail.value}
          className="cand_rep_det_resume cmn_txt_undlin"
        >
          Download Resume
        </a>
      ) : (
        <p key={detail.label}>{detail.value}</p>
      )
    )}
  </div>
);

export default CandidateSummaryDetails;
