import React, { useEffect, useRef } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AuthContext from './shared/contexts/AuthContext';
import MainLayout from './shared/components/MainLayout/MainLayout';
import AdminMainLayout from './Admin/MainLayout/MainLayout';
import ContentAdminMainLayout from './ContentAdmin/MainLayout/MainLayout';
import AdminLoginForm from './Admin/Login/AdminLoginForm';
import ContentAdminLogin from './ContentAdmin/Login/ContentAdminLogin';
import AdminRoute from './Routings/AdminRoute';
import ContentAdminRoute from './Routings/ContentAdminRoute';
import UserRoute from './Routings/UserRoute';
import LoginRoute from './Routings/LoginRoute';
import OnBoard from './User/Pages/OnBoard';
import PaymentsRoute from './Routings/PaymentsRoute';
import useLoginContextData from './shared/hooks/useLoginContextData';
import Welcome from './User/Pages/Welcome';
import { useOnlineStatus } from './shared/hooks/useOnlineStatus';
import useSnackbar from './shared/hooks/SnackBarHook';

function Routing() {
  const history = createBrowserHistory();
  const { isOnline } = useOnlineStatus();
  const [openSnackbar] = useSnackbar();

  const isPayment = history.location.pathname.split('/')[1] === 'payments';
  const {
    isLoggedIn,
    userId,
    userData,
    userRole,
    clientId,
    isMasAdmin,
    isMasContentAd,
    isAdditionalDetails,
    setUserDetails,
    setClientDetails,
    setIsAdditionalDetailsFilled,
    login,
    logout,
    reload,
    setreload,
    setMFAToken,
    isMFAEnable,
  } = useLoginContextData();

  setInterval(() => {
    const loginDetails = localStorage.getItem('loginDetails')
      ? JSON.parse(atob(localStorage.getItem('loginDetails')))
      : null;
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    if (isLoggedIn && loginDetails?.expirationTime <= currentTimestamp) {
      logout();
    }
  }, 30000);

  let routes;

  if (!isAdditionalDetails) {
    localStorage.setItem(
      'userHelp',
      JSON.stringify({ isEnabled: true, stage: 0 })
    );
    routes = (
      <Routes>
        <Route path="/on-board" element={<OnBoard />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="*" element={<Navigate to="/on-board" replace />} />
      </Routes>
    );
  } else if (isPayment) {
    routes = <PaymentsRoute />;
  } else if (isMasAdmin) {
    if (isLoggedIn) {
      routes = <AdminRoute />;
    } else {
      routes = (
        <Routes>
          <Route path="/admin/login" element={<AdminLoginForm />} />
          <Route path="*" element={<Navigate to="/admin/login" replace />} />
        </Routes>
      );
    }
  } else if (isMasContentAd) {
    if (isLoggedIn) {
      routes = <ContentAdminRoute />;
    } else {
      routes = (
        <Routes>
          <Route path="/content-admin/login" element={<ContentAdminLogin />} />
          <Route
            path="*"
            element={<Navigate to="/content-admin/login" replace />}
          />
        </Routes>
      );
    }
  } else if (isLoggedIn) {
    routes = <UserRoute userRole={userRole} />;
  } else {
    routes = <LoginRoute />;
  }

  let layout;
  if (!isAdditionalDetails) layout = routes;
  else if (isLoggedIn && isMasAdmin)
    layout = <AdminMainLayout userData={userData}>{routes}</AdminMainLayout>;
  else if (isLoggedIn && isMasContentAd)
    layout = (
      <ContentAdminMainLayout userData={userData}>
        {routes}
      </ContentAdminMainLayout>
    );
  else if (isPayment) layout = routes;
  else if (isLoggedIn)
    layout = <MainLayout userData={userData}>{routes}</MainLayout>;
  else layout = routes;

  useEffect(() => {
    if (!isOnline) {
      openSnackbar(
        'Network error. Please check your internet connection.',
        'error'
      );
    }
  }, [isOnline]);

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn,
          userId,
          userData,
          clientId,
          isAdditionalDetails,
          isMasAdmin,
          setUserDetails,
          setClientDetails,
          setIsAdditionalDetailsFilled,
          login,
          logout,
          reload,
          setreload,
          setMFAToken,
          isMFAEnable,
          isMasContentAd,
        }}
      >
        <div className="cmn_container">{layout}</div>
      </AuthContext.Provider>
    </>
  );
}
export default Routing;
