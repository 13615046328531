import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '../UIElements/DashboardIcon/DashboardIcon';
import FileIcon from '../UIElements/FileIcon/FileIcon';
import BrainSkill from '../BrainSkill/BrainSkill';
import BooksIcon from '../BooksIcon/BooksIcon';
import UserIcon from '../UIElements/UserIcon';
import CameraWebIcon from '../UIElements/CameraWebIcon';
import AuthContext from '../../contexts/AuthContext';
import './sidebar.css';

const SideNavBar = props => {
  const auth = useContext(AuthContext);
  return (
    <div
      className={`sidbr_container ${
        props.isComponentVisible ? 'mbl_active' : ''
      } cmn_box_shadow_x sidbar_mbl_vu`}
    >
      <div className="sidbar_mnu_container clr_bg_white">
        <ul>
          <li>
            <NavLink to="/dashboard" className="cmn_icon_txt">
              <DashboardIcon />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${auth.userId}/tests`} className="cmn_icon_txt">
              {/* <NavLink to="/tests" className="cmn_icon_txt"> */}
              <FileIcon />
              <span>My Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/skill-library" className="cmn_icon_txt">
              <BooksIcon />
              <span>Skill Library</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/test-library" className="cmn_icon_txt">
              <BrainSkill />
              <span>Test Library</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/candidates" className="cmn_icon_txt">
              <UserIcon />
              <span>Candidates</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/proctor" className="cmn_icon_txt">
              <CameraWebIcon />
              <span>
                Live Proctor <sup className="cmn_beta_icon_label">Beta</sup>
              </span>
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/report" className="cmn_icon_txt">
              <i className="icon-sk_pie_chart" />
              <span>Reports</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SideNavBar;
