import React, { useState, useReducer, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import StateProviderContext from '../../contexts/StateProviderContext';
import useLoginContextData from '../../hooks/useLoginContextData';
import { SocketSignalReducer } from '../../Reducers/SocketSignalReducer';

const socketSignalInit = {
  testStatus: false,
};

const StateProvider = ({ children }) => {
  const activeHttpRequests = useRef([]); // to store active http requests
  const [candidates, setCandidates] = useState([]); //  llive test candidates array
  const [proctorType, setProctorType] = useState('camera'); // p2p connection mode
  const [iceCandidate, setIceCandidate] = useState([]); // remote icecandidats array
  const [proctorCandidate, setProctorCandidate] = useState(''); // live proctor selected candidate id
  const [iceGatherState, setIceGatherState] = useState(false); // icegathering state
  const [peerState, setPeerState] = useState(true); // icecandidate connection state

  const [socketSignals, dispatchSignals] = useReducer(
    SocketSignalReducer,
    socketSignalInit
  );
  const testStateRef = useRef(false);
  const loginUserData = useLoginContextData();
  const userId = loginUserData.userId || localStorage.getItem('uid');
  const socketRef = useRef(null);

  // user websocket register event
  const registerUser = userDataId => {
    socketRef.current.emit('register', {
      user: userDataId,
      message: 'connected',
    });
  };

  // candidates started test
  const userJoined = data => {
    setCandidates(prevCandidates => [...prevCandidates, data]);
  };

  // candidates exited test
  const userExited = data => {
    setCandidates(prevCandidates =>
      prevCandidates.filter(candid => candid.transportId !== data.transportId)
    );
  };

  // candidate offer send to signaling server
  const candidCreateOffer = (id, offer) => {
    socketRef.current.emit('createOffer', {
      from: localStorage.getItem('uid'),
      to: id,
      offer,
    });
  };

  useEffect(() => {
    socketRef.current = io.connect(process.env.REACT_APP_SOCKET_URL, {
      // WARNING: in that case, there is no fallback to long-polling
      transports: ['websocket', 'polling'], // or [ "websocket", "polling" ] (the order matters)
    });

    const handleConnect = () => {
      console.log('Connected to the skillrobo server');
    };

    const handleMessage = data => {
      console.log(data); // for signal testing purpose
    };

    const ChangeTestStatus = () => {
      dispatchSignals({ type: 'changeTestStatus' });
      testStateRef.current = true;
    };

    // Attach event listeners
    socketRef.current.on('connect', handleConnect);
    socketRef.current.on('message', handleMessage);
    socketRef.current.on('changeTestStatus', ChangeTestStatus);
    socketRef.current.on('candidJoined', userJoined);
    socketRef.current.on('candidExit', userExited);

    if (
      userId &&
      (!localStorage.getItem('isDelegatedUser') ||
        (localStorage.getItem('isDelegatedUser') &&
          localStorage.getItem('isDelegatedUser') == 'false')) && // checking the user is delegated
      localStorage.getItem('uRole') == 'admin'
    ) {
      registerUser(userId);
    } else if (
      localStorage.getItem('uid') &&
      (!localStorage.getItem('isDelegatedUser') ||
        (localStorage.getItem('isDelegatedUser') &&
          localStorage.getItem('isDelegatedUser') == 'false')) &&
      localStorage.getItem('uRole') == 'admin'
    ) {
      registerUser(localStorage.getItem('uid'));
    }

    return () => {
      socketRef.current.off('connect', handleConnect);
      socketRef.current.off('message', handleMessage);
      socketRef.current.off('changeTestStatus', ChangeTestStatus);
      socketRef.current.off('candidJoined', userJoined);
      socketRef.current.off('candidExit', userExited);
      socketRef.current.disconnect();
    };
  }, [userId]);

  return (
    <StateProviderContext.Provider
      value={{
        activeHttpRequests,
        userId,
        registerUser,
        testStateRef,
        socketSignals,
        candidates,
        socket: socketRef,
        iceCandidate,
        setIceCandidate,
        candidCreateOffer,
        proctorType,
        setProctorType,
        proctorCandidate,
        setProctorCandidate,
        peerState,
        setPeerState,
        iceGatherState,
        setIceGatherState,
      }}
    >
      {children}
    </StateProviderContext.Provider>
  );
};
export default StateProvider;
