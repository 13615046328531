import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../../shared/components/FormElements/InputField';
import Button from '../../shared/components/FormElements/Button';
import { Email_Regex } from '../../shared/constants/AppConstants';
import AuthContext from '../../shared/contexts/AuthContext';
import Logo from '../../shared/components/UIElements/Logo';
import '../../Login/Pages/login.css';
import Form from '../../shared/components/FormElements/Form';
import { useHttpClient } from '../../shared/hooks/useHttpClient';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import ATag from '../../shared/components/UIElements/ATag';
import './Signup.css';
import SignupTerms from '../Components/SignupTerms';

const normalDomains = [
  'gmail',
  'yahoo',
  'hotmail',
  'live',
  'aol',
  'outlook',
  'icloud',
];
const Signup = () => {
  const navigate = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const auth = useContext(AuthContext);
  const { sendRequest, error } = useHttpClient();

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onSignup = async data => {
    closeSnackbar();
    try {
      const responseData = await sendRequest(
        'users/signup',
        'POST',
        JSON.stringify({
          name: data.name,
          email: data.email,
          password: data.password,
        }),
        { 'Content-Type': 'application/json' },
        true
      );
      window.smartlook('identify', responseData.userId, {
        email: responseData.email,
      });
      // commented on 04/03/2024 start
      // window.FS.identify(responseData.userId, {
      //   email: responseData.email,
      // });
      // end
      auth.login(
        responseData.userId,
        responseData.name,
        responseData.image,
        responseData.email,
        responseData.logo,
        responseData.token,
        responseData.role,
        responseData.clientId,
        false, // master admin
        responseData.isAdditionalDetailsFilled,
        responseData.emailSettings,
        responseData.emailTemplates,
        {
          plan: responseData.plan,
          clientDomain: responseData.clientDomain,
          subscriptionDate: responseData.subscriptionDate,
          subscriptionExpiry: responseData.subscriptionExpiry,
        }
      );
      let loginEvent = new CustomEvent('loginEvent', {
        detail: {
          data: responseData,
        },
      });

      document.dispatchEvent(loginEvent);
      navigate('/welcome');
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <div className="login_body_container">
        <div className="login_body_center">
          <div className="login_top">
            <ATag href="https://www.skillrobo.com/" target="_blank">
              <Logo />
            </ATag>
          </div>
          <div className="login_form_container login_ctr">
            <div className="login_sub_head">
              <span>
                Enter your name, email and your password for signing up.
              </span>
            </div>
            <Form onSubmit={onSignup}>
              <div className="cmn_form_input_container cmn_wi_full">
                <InputField
                  id="name"
                  name="name"
                  type="text"
                  label="Name"
                  placeholder=""
                  validation={{
                    required: 'Required',
                  }}
                />
              </div>
              <div className="cmn_form_input_container cmn_wi_full">
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  label="Business email"
                  placeholder=""
                  validation={{
                    required: 'Required',
                    pattern: {
                      value: Email_Regex,
                      message: 'Invalid email address',
                    },
                    validate: val => {
                      const domain = val.split('@')[1];
                      const domainName = domain.split('.')[0];
                      if (normalDomains.indexOf(domainName) !== -1) {
                        return 'Private email id not allowed. Please use your business email to sign up.';
                      }
                    },
                  }}
                />
              </div>
              <div className="cmn_form_input_container cmn_wi_full">
                <InputField
                  id="password"
                  name="password"
                  type="password"
                  label="Choose your password"
                  placeholder=""
                  validation={{
                    required: 'Required',
                    minLength: {
                      value: 6,
                      message: 'Password must be atleast 6 characters',
                    },
                  }}
                />
              </div>
              <Button
                type="submit"
                className="cmn_btn cmn_primary cmn_btn_full"
              >
                Signup
              </Button>
              <div className="cmn_form_btn_container login_here">
                Already signed up?
                <ATag className="cmn_mgn_lef cmn_txt_undlin" href="/login">
                  Login here
                </ATag>
              </div>
              <SignupTerms />
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
