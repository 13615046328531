import React, { useRef, useEffect } from 'react';
import PaddedPieChart from '../../shared/components/Charts/PaddedPieChart';
import Stars from '../../shared/components/Stars/Stars';
import CandidatePsychometricTraitAnalysis from './CandidatePsychometricTraitAnalysis';

const CandidatePsychometricAnalysis = ({
  skill,
  skillChartData,
  skillStarData,
}) => {
  const skillTraitsOverall = useRef('');

  useEffect(() => {
    let skillResultTraits = `<div class='psychometric_overall_traits'>`;

    skill.traits.forEach(trait => {
      const traitDetails = trait.trait;

      if (trait.marksAttended) {
        const scorePercent = Math.round(
          (trait.marksScored / trait.marksAttended) * 100
        );
        traitDetails.results.some(result => {
          if (result.maximumPercentage >= scorePercent) {
            if (result.message.includes('Poor')) {
              skillResultTraits += `<div class="trait_poor">${traitDetails.name}</div>`;
              return true;
            }
            if (result.message.includes('Fair')) {
              skillResultTraits += `<div class="trait_fair">${traitDetails.name}</div>`;
              return true;
            }
            if (result.message.includes('Good')) {
              skillResultTraits += `<div class="trait_good">${traitDetails.name}</div>`;
              return true;
            }
            if (result.message.includes('Great')) {
              skillResultTraits += `<div class="trait_great">${traitDetails.name}</div>`;
              return true;
            }
            if (result.message.includes('Excellent')) {
              skillResultTraits += `<div class="trait_exclnt">${traitDetails.name}</div>`;
              return true;
            }
          }
        });
      } else
        skillResultTraits += `<div class="trait_no_result">${traitDetails.name}</div>`;
    });

    skillResultTraits += '</div>';

    skillTraitsOverall.current.innerHTML = skillResultTraits;
  }, []);

  return (
    <div className="cmn_form_fields cmn_mgn_top">
      <div className="general_skill_section">
        <div className="gen_left_chart_sec overall_skill_chart">
          <div className="das_analyst_tab_ovrl_cnd_anlys">
            <div className="das_cnd_details">
              <PaddedPieChart
                innerRadius={60}
                width={150}
                height={150}
                data={skillChartData}
                dataKey="value"
              />
            </div>
          </div>
          <span className="star_box">
            <Stars feedbackRating={skillStarData} />
          </span>
        </div>
        <div className="gen_right_sec">
          <div className="gen_desc_text" ref={skillTraitsOverall} />
          <div className="psych_skill_legend_section">
            <div className="skill_leg_type">
              <div className="leg_box trait_exclnt" />
              <div className="leg_text">Excellent</div>
            </div>
            <div className="skill_leg_type">
              <div className="leg_box trait_grt" />
              <div className="leg_text">Great</div>
            </div>
            <div className="skill_leg_type">
              <div className="leg_box trait_good" />
              <div className="leg_text">Good</div>
            </div>
            <div className="skill_leg_type">
              <div className="leg_box trait_fair" />
              <div className="leg_text">Fair</div>
            </div>
            <div className="skill_leg_type">
              <div className="leg_box trait_poor" />
              <div className="leg_text">Poor</div>
            </div>
            <div className="skill_leg_type">
              <div className="leg_box trait_no_rslt" />
              <div className="leg_text">No result</div>
            </div>
          </div>
          {skill.traits.map(trait => (
            <CandidatePsychometricTraitAnalysis trait={trait} key={trait.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CandidatePsychometricAnalysis;
