import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const ReportIcon = props => {
  return (
    <>
      {props.to ? (
        <Link {...props}>
          <FontAwesomeIcon
            icon={['fas', 'files']}
            style={{ color: props.color }}
            {...props}
            className="icon-file_copy1"
          />
        </Link>
      ) : (
        <FontAwesomeIcon
          icon={['fas', 'files']}
          {...props}
          style={{ color: props.color }}
          className={`icon-file_copy1 ${props.className}`}
        />
      )}
    </>
  );
};

export default ReportIcon;
