import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../shared/components/UIElements/Logo';
import { useUserAPI } from '../../shared/hooks/useUserAPI';
import EmailConfirmed from '../../shared/images/email_confirmed.png';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import './SignupVerification.css';

const SignupVerification = () => {
  const [isVerified, setIsVerified] = useState(true);
  const { verifyUser, error } = useUserAPI();
  const { token } = useParams();
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  useEffect(() => {
    const verifyUserToken = async () => {
      try {
        await verifyUser(token);
        setIsVerified(true);
      } catch (err) {
        console.error(err);
      }
    };
    verifyUserToken();
  }, []);

  return (
    <div className="cmn_container">
      {isVerified && (
        <div className="em_cn_container">
          <div className="em_cn_main_content">
            <div className="em_cn_logo">
              <Logo />
            </div>
            <div className="em_cn_text_container">
              <img src={EmailConfirmed} alt="Email Confirmation" />
              <div className="em_cn_lrg_text">
                <span> Account verified </span>
              </div>
              <div className="em_cn_sml_text">
                <span>
                  Thank you, Your email has been verfied. Your account is active
                  now. Please use the link below to login to your account.
                </span>
              </div>
              <div>
                <Link
                  to="/login"
                  className="cmn_btn cmn_primary cmn_btn_full em_login_btn"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupVerification;
