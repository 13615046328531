import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThumbsUpIcon = props => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'thumbs-up']}
      className={`icon-thumb_up_alt1  ${props.className || ''}`}
    />
  );
};

export default ThumbsUpIcon;
