import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routing from './Routing';
import SnackBarProvider from './shared/components/SnackBar/SnackBar';
import LoaderProvider from './shared/components/Loader/Loader';
import ConfirmationDialogProvider from './shared/components/ConfirmationAlert/ConfirmationDialogProvider';
import StateProvider from './shared/components/StateProvider/StateProvider';
import PeerContextProvider from './shared/contexts/PeerContext';
import './shared/components/FontAwesome/FontAwesome';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <StateProvider>
      <PeerContextProvider>
        <SnackBarProvider>
          <LoaderProvider>
            <ConfirmationDialogProvider>
              <Routing />
            </ConfirmationDialogProvider>
          </LoaderProvider>
        </SnackBarProvider>
      </PeerContextProvider>
    </StateProvider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
