import { useRef, createContext } from 'react';

const UserHelpContext = createContext({
  isEnabled: false,
  stage: 0,
  setUserStage: () => {},
  setStage: () => {},
  setIsEnabled: () => {},
  showHelp: false,
  setShowHelp: () => {},
  changeHelpStatus: () => {},
  isUserActive: true,
  setUserActive: () => {},
  countdown: 10000,
  setcountdown: () => {},
  setHelpIsEnable: () => {},
  pendingState: '',
  setPendingState: () => {},
});
export default UserHelpContext;
