import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StarIcon = props => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'star']}
      onClick={props.onClick}
      {...props}
    />
  );
};

export default StarIcon;
