import React from 'react';

const LinkButton = ({ children, ...props }) => {
  return (
    <div className="cmn_form_btn_container">
      {props.pretext && <span>{props.pretext}</span>}
      {children}
      {props.posttext && <span>{props.posttext}</span>}
    </div>
  );
};

export default LinkButton;
