import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardIcon = ({ children, type, ...props }) => (
  <>
    <FontAwesomeIcon
      icon={['far', 'credit-card']}
      onClick={props.onClick}
      {...props}
    />
    {children || ''}
  </>
);
export default CardIcon;
