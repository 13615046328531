import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrevIcon = ({ className }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={['fas', 'arrow-left']}
        className={`icon-arrow_back1 ${className}`}
      />
    </div>
  );
};

export default PrevIcon;
