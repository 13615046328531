import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoaderElement from '../shared/components/Loader/LoaderElement';

const PaymentStatus = lazy(() => import('../Payment/Pages/PaymentStatus'));

const PaymentRoutes = () => (
  <Suspense fallback={<LoaderElement />}>
    <Routes>
      <Route path="/payments/status" element={<PaymentStatus />} />
    </Routes>
  </Suspense>
);

export default PaymentRoutes;
