import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CameraWebIcon = ({ children, type, ...props }) => (
  <>
    <FontAwesomeIcon
      icon={['fas', 'camera-web']}
      onClick={props.onClick}
      {...props}
    />
    {children || ''}
  </>
);
export default CameraWebIcon;
