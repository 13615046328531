import React from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import './Charts.css';

const PaddedPieChart = props => {
  return (
    <>
      <ResponsiveContainer>
        <PieChart width={props.width} height={props.height}>
          <Pie
            data={props.data}
            innerRadius={60}
            outerRadius={80}
            labelLine={!props.label}
            label={props.label}
            startAngle={props.startAngle}
            endAngle={props.endAngle}
            fill="#dcdcdc"
            paddingAngle={5}
            dataKey={props.dataKey}
          >
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {props.legend ? (
            <Legend
              verticalAlign="bottom"
              height={36}
              iconSize={12}
              iconType="circle"
            />
          ) : null}
          {props.toolTip ? <Tooltip /> : null}
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default PaddedPieChart;
