import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from './ATag';

const CloseSolidIcon = props => {
  const { className, ...rest } = props;
  return (
    <div className={props.className}>
      <ATag onClick={props.onClick} {...rest}>
        <FontAwesomeIcon
          icon={['fas', 'circle-xmark']}
          className="icon-cancel3"
        />
      </ATag>
    </div>
  );
};

export default CloseSolidIcon;
