import React, { useContext, useEffect } from 'react';
import InputField from '../../shared/components/FormElements/InputField';
import useSnackbar from '../../shared/hooks/SnackBarHook';
import Logo from '../../shared/components/UIElements/Logo';
import AuthContext from '../../shared/contexts/AuthContext';
import Form from '../../shared/components/FormElements/Form';
import { useHttpClient } from '../../shared/hooks/useHttpClient';
import { Email_Regex } from '../../shared/constants/AppConstants';
import { useUserAPI } from '../../shared/hooks/useUserAPI';
import './AdminLogin.css';

const AdminLoginForm = () => {
  const auth = useContext(AuthContext);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { sendRequest, error } = useHttpClient();
  const { verifyMFALogin, error: apiError } = useUserAPI();

  useEffect(() => {
    if (error !== undefined && error.length > 0) {
      openSnackbar(error, 'error');
    } else if (apiError !== undefined && apiError.length > 0) {
      openSnackbar(apiError, 'error');
    }
  }, [error, apiError]);

  const onLogin = async data => {
    closeSnackbar();
    try {
      const responseData = await sendRequest(
        'users/login-superadmin',
        'POST',
        JSON.stringify({ email: data.email, password: data.password }),
        { 'Content-Type': 'application/json' }
      );

      // checking MFA is enabled
      if (responseData.isMFaEnable) {
        auth.setMFAToken(responseData.token);
      } else {
        auth.login(
          responseData.userId,
          responseData.name,
          responseData.image,
          responseData.email,
          null,
          responseData.token,
          responseData.role,
          {},
          true, // master admin
          true
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onMFAVerify = async data => {
    closeSnackbar();
    try {
      let responseData = await verifyMFALogin(data.mfacode);
      auth.login(
        responseData.userId,
        responseData.name,
        responseData.image,
        responseData.email,
        null,
        responseData.token,
        responseData.role,
        {},
        true, // master admin
        true
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="login_body_container">
        <div className="login_body_center">
          <div className="login_top">
            <Logo />
          </div>
          <div className="login_form_container login_ctr">
            {auth.isMFAEnable ? (
              <>
                <div className="login_sub_head">
                  <span>Enter MFA code to login.</span>
                </div>
                <Form onSubmit={onMFAVerify}>
                  <div className="cmn_form_input_container cmn_wi_full">
                    <InputField
                      id="mfacode"
                      name="mfacode"
                      type="number"
                      label="MFA Code"
                      placeholder="Enter MFA Code"
                      validation={{
                        required: 'Required',
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="cmn_btn cmn_primary cmn_btn_full"
                  >
                    Login
                  </button>
                </Form>
              </>
            ) : (
              <>
                <div className="login_sub_head">
                  <span>
                    Enter your email and your password to access SkillRobo.
                  </span>
                </div>
                <Form onSubmit={onLogin}>
                  <div className="cmn_form_input_container cmn_wi_full">
                    <InputField
                      id="email"
                      name="email"
                      type="email"
                      label="Email ID"
                      placeholder=""
                      validation={{
                        required: 'Required',
                        pattern: {
                          value: Email_Regex,
                          message: 'Invalid email address',
                        },
                      }}
                    />
                  </div>
                  <div className="cmn_form_input_container cmn_wi_full">
                    <InputField
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      placeholder=""
                      validation={{
                        required: 'Required',
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="cmn_btn cmn_primary cmn_btn_full"
                  >
                    Login
                  </button>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLoginForm;
