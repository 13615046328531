import React, { useContext, useEffect } from 'react';
import InputField from '../../shared/components/FormElements/InputField';
import Button from '../../shared/components/FormElements/Button';
import { Email_Regex } from '../../shared/constants/AppConstants';
import Logo from '../../shared/components/UIElements/Logo';
import AuthContext from '../../shared/contexts/AuthContext';
import StateProviderContext from '../../shared/contexts/StateProviderContext';
import './login.css';
import Form from '../../shared/components/FormElements/Form';
import { useHttpClient } from '../../shared/hooks/useHttpClient';
import useSnackbar from '../../shared/hooks/SnackBarHook';

const LoginForm = () => {
  const auth = useContext(AuthContext);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { registerUser } = useContext(StateProviderContext);
  const { sendRequest, error } = useHttpClient();
  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onLogin = async data => {
    closeSnackbar();
    try {
      const responseData = await sendRequest(
        'users/login',
        'POST',
        JSON.stringify({ email: data.email, password: data.password }),
        { 'Content-Type': 'application/json' }
      );
      window.smartlook('identify', responseData.userId, {
        email: responseData.email,
      });
      // commented on 04/03/2024 start
      // window.FS.identify(responseData.userId, { email: responseData.email });
      // end
      // window.__insp.push(['identify', responseData.email]);
      auth.login(
        responseData.userId,
        responseData.name,
        responseData.image,
        responseData.email,
        responseData.logo,
        responseData.token,
        responseData.role,
        responseData.clientId,
        false, // master admin
        responseData.isAdditionalDetailsFilled,
        responseData.emailSettings,
        responseData.emailTemplates,
        {
          plan: responseData.plan,
          clientDomain: responseData.clientDomain,
          subscriptionDate: responseData.subscriptionDate,
          subscriptionExpiry: responseData.subscriptionExpiry,
        }
      );

      registerUser(responseData.userId);

      // login dispatch event for data analysis
      let loginEvent = new CustomEvent('loginEvent', {
        detail: {
          data: responseData,
        },
      });

      document.dispatchEvent(loginEvent);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="login_body_container">
        <div className="login_body_center">
          <div className="login_top">
            <Logo />
          </div>
          <div className="login_form_container login_ctr">
            <div className="login_sub_head">
              <span>
                Enter your email and your password to access SkillRobo.
              </span>
            </div>
            <Form onSubmit={onLogin} className="cmn_wi_post">
              <div className="cmn_form_input_container cmn_wi_full">
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  label="Email ID"
                  placeholder=""
                  validation={{
                    required: 'Required',
                    pattern: {
                      value: Email_Regex,
                      message: 'Invalid email address',
                    },
                  }}
                />
              </div>
              <div className="cmn_form_input_container cmn_wi_full cmn_wi_flex  ">
                <div>
                  <InputField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    placeholder=""
                    validation={{
                      required: 'Required',
                    }}
                    isShowPassword
                  />
                </div>
              </div>

              <Button
                buttontype="Link"
                to="/resetpassword"
                className="cmn_btn cmn_primary_txt cmn_pad_ze_fi"
              >
                Forgot Password?
              </Button>
              <button
                type="submit"
                id="sr_login_btn"
                className="cmn_btn cmn_primary cmn_btn_full"
              >
                Login
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginForm;
