import React from 'react';
import Button from './Button';

const FormSubmitControl = props => {
  return (
    <>
      <div className={props.containerClass || 'cmn_form_btn_container'}>
        {props.children}
        <Button type="submit" className="cmn_btn cmn_primary cmn_save_btn">
          {props.submitButtonText || 'Save'}
        </Button>
        <Button
          type="button"
          onClick={props.onCancel}
          className="cmn_btn cmn_primary_txt cmn_secondary_btn"
        >
          {props.cancelButtonText || 'Cancel'}
        </Button>
      </div>
    </>
  );
};

export default FormSubmitControl;
