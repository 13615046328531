import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SignOutIcon = ({ children, type, ...props }) => (
  <>
    <FontAwesomeIcon
      icon={['far', 'right-from-bracket']}
      onClick={props.onClick}
      {...props}
    />
    {children || ''}
  </>
);
export default SignOutIcon;
