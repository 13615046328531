import React from 'react';
import Accordian from '../../shared/components/UIElements/Accordian';
import CandidateSkillAnalysis from './CandidateSkillAnalysis';
import CandidatePsychometricAnalysis from './CandidatePsychometricAnalysis';
import useConfirmationDialog from '../../shared/hooks/useConfirmationDialog';
import InfoIcon from '../../shared/components/UIElements/InfoIcon';

const SkillSummarySection = ({
  candidate,
  skills,
  openPopupToggle,
  isPsychometric,
}) => {
  let skillChartData;
  let skillStarData = 0;
  let skillResultStatus;
  const { getConfirmation, resetDialog } = useConfirmationDialog();

  const onView = async skill => {
    await getConfirmation({
      title: skill.skill.skillName,
      message: skill.skill.description,
      cancelText: 'Close',
      isSubmit: false,
    });
  };

  return skills.length > 0
    ? skills.map(skill => {
        const scoredPercentage = skill.scoredPercentage;
        skillChartData = [
          {
            name: 'scored',
            value: skill.marksScored,
            color: '#C5FFC5',
          },
          {
            name: 'totalMarks',
            value: skill.totalMarks,
            color: '#D5D5D5',
          },
        ];

        if (scoredPercentage >= '0' && scoredPercentage <= '20') {
          skillResultStatus = 'skill_text_poor';
          skillStarData = 1;
        } else if (scoredPercentage >= '21' && scoredPercentage <= '40') {
          skillResultStatus = 'skill_text_fair';
          skillStarData = 2;
        } else if (scoredPercentage >= '41' && scoredPercentage <= '60') {
          skillResultStatus = 'skill_text_good';
          skillStarData = 3;
        } else if (scoredPercentage >= '61' && scoredPercentage <= '80') {
          skillResultStatus = 'skill_text_great';
          skillStarData = 4;
        } else {
          skillResultStatus = 'skill_text_excellent';
          skillStarData = 5;
        }

        return isPsychometric ? (
          <Accordian
            key={skill.id}
            defaultOpen
            containerClass={skill.skill.skillName}
            content={
              <CandidatePsychometricAnalysis
                skillStarData={skillStarData}
                skillChartData={skillChartData}
                skill={skill}
              />
            }
            title={
              <span className="sec_ttl">
                <div className="test_invite_container">
                  <div className="ovs_skill_detail">
                    <span className="skill_anlys_name ">
                      <span className="res_text">{skill.skill.skillName}</span>
                      <InfoIcon
                        className="p5"
                        onClick={e => {
                          e.stopPropagation();
                          onView(skill);
                        }}
                      />
                    </span>
                    <span className="ovs_skill_score">
                      {/* <InfoIcon
                        onClick={e => {
                          showInfo(e);
                        }}
                      /> */}
                    </span>
                  </div>
                </div>
              </span>
            }
          />
        ) : (
          <Accordian
            key={skill.id}
            defaultOpen
            containerClass={skill.skill.skillName}
            content={
              <CandidateSkillAnalysis
                openPopupToggle={openPopupToggle}
                skill={skill}
                skillStarData={skillStarData}
                skillChartData={skillChartData}
                candidate={candidate}
              />
            }
            title={
              <span className="sec_ttl">
                <div className="test_invite_container">
                  <div className="ovs_skill_detail">
                    <span className="skill_anlys_name ">
                      <span className={`res_text ${skillResultStatus}`}>
                        {skill.skill.skillName} ({skill.marksScored}/
                        {skill.totalMarks})
                      </span>
                    </span>
                  </div>
                </div>
              </span>
            }
          />
        );
      })
    : '';
};

export default SkillSummarySection;
