import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ATag from '../ATag';

const PenIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <ATag onClick={onClick} {...rest}>
      <FontAwesomeIcon
        icon={['fas', 'pen-clip']}
        className={`icon-penclip ${props.className}`}
      />
    </ATag>
  );
};

export default PenIcon;
