import React from 'react';
import ThumbsUpIcon from '../../shared/components/UIElements/ThumbsUpIcon';
import ThumbsDownIcon from '../../shared/components/UIElements/ThumbsDownIcon';

const CandidateSkillReview = ({
  strongSkills,
  candidate,
  improvementSkills,
  candidateScorePercentage,
  timePercentage,
  generalSkills,
}) => (
  <div className="skills_review">
    <div className="cand_review">
      {generalSkills.length > 0 && (
        <>
          <p className="cand_review_text">
            <span className="thumbs_up">
              <ThumbsUpIcon />
              <span className="review_cand_name">
                {candidate.candidateReportDetails.additionalDetails[0].value}
              </span>
            </span>
            is
            <span className="review_cand_name">strong</span>
            in
            {strongSkills.length >= 1 ? (
              strongSkills.map((skill, index) => (
                <span className="cmn_skill_rev_text strong_review" key={index}>
                  {skill}
                </span>
              ))
            ) : (
              <span> none of the skills</span>
            )}
          </p>

          <p className="cand_review_text">
            <span className="thumbs_dwn">
              <ThumbsDownIcon />
              <span className="review_cand_name">
                {candidate.candidateReportDetails.additionalDetails[0].value}
              </span>
            </span>
            needs
            <span className="review_cand_name">improvement</span>
            in
            {improvementSkills.length >= 1 ? (
              improvementSkills.map((skill, index) => (
                <span className="cmn_skill_rev_text poor_review" key={index}>
                  {skill}
                </span>
              ))
            ) : (
              <span> none of the skills</span>
            )}
          </p>
        </>
      )}
    </div>
    <div className="score_result">
      <p className="scored_text">
        <span className="highlighted_text">
          {candidate.candidateReportDetails.additionalDetails[0].value}
        </span>
        has scored {candidateScorePercentage}% and completed assessment in{' '}
        {timePercentage}% of the allocated time
      </p>
    </div>
  </div>
);

export default CandidateSkillReview;
