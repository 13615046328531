import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
} from 'react';
import StateProviderContext from './StateProviderContext';

export const PeerContext = createContext();

export const usePeer = () => useContext(PeerContext);

const PeerContextProvider = ({ children }) => {
  const {
    candidCreateOffer,
    setProctorType,
    socket,
    proctorCandidate,
    setPeerState,
    setIceGatherState,
  } = useContext(StateProviderContext);
  // peer remote stream
  const [remoteStream, setRemoteStream] = useState();
  const [peerRetry, setPeerRetry] = useState(false);
  const [iceServerConfig, setIceServerConfig] = useState([]);
  const peer = useRef(null);

  // creating offer to candidate
  async function createCandidOffer(id) {
    const offer = await peer.current.createOffer();
    await peer.current.setLocalDescription(new RTCSessionDescription(offer));
    candidCreateOffer(id, offer);
    return offer;
  }

  // trickling icecandidates
  function handleIceCandidate(e) {
    if (e.candidate && proctorCandidate) {
      socket.current.emit('addIceCandidate', {
        from: localStorage.getItem('uid'),
        to: proctorCandidate,
        iceCandidate: e.candidate,
      });
    }
  }

  // p2p connection status
  function iceConnStateChange() {
    if (
      peer.current.iceConnectionState === 'failed' ||
      peer.current.iceConnectionState === 'disconnected'
    ) {
      setPeerState(false);
    } else {
      setPeerState(true);
    }
  }

  // icecandidate gathering event
  function iceGatheringStateChange(e) {
    let connection = e.target;

    switch (connection.iceGatheringState) {
      case 'gathering':
        /* collection of candidates has begun */
        setIceGatherState(false);
        break;
      case 'complete':
        /* collection of candidates is finished */
        setIceGatherState(true);
        break;
      default:
        setIceGatherState(false);
    }
  }

  // p2p retry connection to establish live proctoring with candidate
  const RetryPeerConnect = () => {
    setPeerRetry(!peerRetry);
  };

  useEffect(() => {
    // initialize p2p connection
    peer.current = new RTCPeerConnection({
      iceServers: iceServerConfig,
    });

    // setting proctor mode camera as default
    setProctorType('camera');

    // clearing previous remote stream
    setRemoteStream(null);

    // p2p connection state reset
    setPeerState(false);

    // Add a transceiver for audio
    peer.current.addTransceiver('audio', { direction: 'sendrecv' });

    // Add a transceiver for video
    peer.current.addTransceiver('video', { direction: 'sendrecv' });

    // ice candidate error event
    const iceCandidateError = error => {
      console.error('ICE candidate error:', error);
    };

    // webrtc peer track event
    const peerTracks = e => {
      if (e.streams) {
        setRemoteStream(e.streams[0]);
      }
    };

    peer.current.addEventListener('icecandidate', handleIceCandidate);
    peer.current.addEventListener('track', peerTracks);

    peer.current.addEventListener('icecandidateerror', iceCandidateError);
    peer.current.addEventListener(
      'iceconnectionstatechange',
      iceConnStateChange
    );
    peer.current.addEventListener(
      'icegatheringstatechange',
      iceGatheringStateChange
    );

    if (proctorCandidate) {
      createCandidOffer(proctorCandidate);
    }

    return () => {
      if (peer.current) {
        peer.current.removeEventListener(
          'icecandidateerror',
          iceCandidateError
        );
        peer.current.removeEventListener('icecandidate', handleIceCandidate);
        peer.current.removeEventListener('track', peerTracks);
        peer.current.removeEventListener(
          'iceconnectionstatechange',
          iceConnStateChange
        );
        peer.current.removeEventListener(
          'icegatheringstatechange',
          iceGatheringStateChange
        );
        peer.current.close();
      }
    };
  }, [proctorCandidate, peerRetry, iceServerConfig]);

  return (
    <PeerContext.Provider
      value={{
        peer,
        remoteStream,
        setRemoteStream,
        RetryPeerConnect,
        iceServerConfig,
        setIceServerConfig,
      }}
    >
      {children}
    </PeerContext.Provider>
  );
};

export default PeerContextProvider;
