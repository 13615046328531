export const StringFormatter = str => {
  return str.toString().replace(/\s/g, '').toLowerCase();
};

export const IsAllTrueInObj = obj => Object.values(obj).every(Boolean);

export const FilterByKey = (source, key, value) =>
  source.filter(
    i => i[key].toString().toLowerCase() === value.toString().toLowerCase()
  );

export const NameToInitials = value => {
  const namesArray = value.split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;

  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(
    0
  )}`;
};

export const getFormatedDate = value => {
  const date = new Date(value);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return `${day} ${month}, ${year}`;
};

export const getFormatedDateTime = date => {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const time = new Intl.DateTimeFormat('en', { timeStyle: 'short' }).format(
    date
  );

  return `${day} ${month}, ${year}, ${time}`;
};

export const getColorCodeByName = name => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00ffffff).toString(16).toUpperCase();
  const colorCode = '00000'.substring(0, 6 - color.length) + color;
  return `#${colorCode}`;
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        error => {
          reject(new Error(`Error getting user location: ${error.message}`));
        }
      );
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
};
