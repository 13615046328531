import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Form from '../../FormElements/Form';
import CandidateInviteCoupon from '../../../../Candidate/Components/CandidateInviteCoupon';
import TagInput from '../../TagInput/TagInput';
import { useTestsAPI } from '../../../hooks/useTestsAPI';
import { useSkillsAPI } from '../../../hooks/useSkillsAPI';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { Email_Regex } from '../../../constants/AppConstants';
import Button from '../../FormElements/Button';
import InputField from '../../FormElements/InputField';
import SelectField from '../../FormElements/SelectField';
import '../../../../Candidate/Pages/InviteCandidate.css';

const InviteCandidateHelp = ({
  resetDialog,
  openSnackbar,
  uData,
  inviteCandidateByEmail,
  isSelfInvite,
  cnTest,
  setCurrentStep,
  setUserStage,
  setTempTestId,
  setIsFirstInvite,
}) => {
  const { getTestsByUserId, error } = useTestsAPI();
  const { getSkillsByTestId } = useSkillsAPI();
  const { getConfirmation } = useConfirmationDialog();
  const [testsData, setTestsData] = useState([]);
  const [emailData, setEmailData] = useState([]);

  const onFirstInviteCandidate = async () => {
    await getConfirmation({
      containerClassName: 'invite_discount',
      title: 'Yay!!!',
      message: (
        <CandidateInviteCoupon
          openSnackbar={openSnackbar}
          resetDialog={resetDialog}
        />
      ),
    });
  };

  const inviteCandidate = async data => {
    try {
      let getSkillTestDetails = await getSkillsByTestId(data.test.value);
      if (data.test.value) {
        setTempTestId(data.test.value);
      }
      if (getSkillTestDetails.length > 0) {
        const payloadData = {
          candidate_emails: isSelfInvite ? uData.userEmail : data.candEmail,
          invite_subject: `Invite to take SkillRobo test - ${data.test.label}`,
          invite_message:
            'Hi,<br/> We are pleased to invite you to our online test. Click the Test Name below to take the test from the comfort of your home. Good Luck!',
          submit_subject: 'Your test has been submitted',
          submit_message: `Hi {Candidate Name},<br> Thanks for submitting your test, we will get back shortly after the assessment review. <br /><br />Regards,<br /> ${uData.userFullName}`,
        };
        const response = await inviteCandidateByEmail(
          data.test.value,
          payloadData
        );
        if (response.isFirstInvite) {
          setIsFirstInvite(true);
        } else {
          setIsFirstInvite(false);
        }
        // if (response.isFirstInvite) onFirstInviteCandidate();
        // else {
        //   navigate(`/${data.test.value}/candidates`);
        //   resetDialog();
        // }
        openSnackbar('Invited candidate successfully', 'success');
        cnTest.setTestId(data.test.value);
        cnTest.setTestName(data.test.label);
        setUserStage(4);
        setCurrentStep('postInvite');
      } else {
        setCurrentStep('noskill');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const getTestsByUser = async () => {
      try {
        const response = await getTestsByUserId();
        const tests = response.tests.map(data => {
          return { value: data.id, label: data.testName };
        });
        setTestsData(tests);
      } catch (err) {
        console.error(err);
        throw err;
      }
    };
    getTestsByUser();
  }, []);

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  return (
    <Form onSubmit={inviteCandidate}>
      <div className="message">
        <div className="cmn_form_fields">
          <div className="cmn_form_input_container cmn_wi_full">
            <SelectField
              id="test"
              name="test"
              label="Select the test"
              placeholder="Select Test"
              defaultValue="All Tests"
              options={testsData}
            />
          </div>
          {!isSelfInvite && (
            <div className="cmn_form_input_container cmn_wi_full">
              <Controller
                name="candEmail"
                id="candEmail"
                rules={{
                  required: 'Required',
                  validate: value => {
                    if (value !== '') {
                      const emails = value.split(',');
                      let validationFailed;
                      emails.some(val => {
                        if (!val.match(Email_Regex)) {
                          validationFailed = true;
                          return true;
                        }
                      });
                      if (validationFailed) return 'invalid email address';
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <TagInput
                    {...field}
                    tagData={{ emailData, setEmailData }}
                    placeholder="Press enter to add email"
                    label="Email of the candidate you wanted to evaluate?"
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div className="action help_btn_cont">
        <Button
          className="cmn_btn cmn_primary_txt cmn_secondary_btn"
          onClick={resetDialog}
        >
          Cancel
        </Button>
        <Button type="submit" className="cmn_btn cmn_primary">
          Invite
        </Button>
      </div>
    </Form>
  );
};

export default InviteCandidateHelp;
