import React from 'react';

const LoaderElement = () => {
  return (
    <div className="loader_container">
      <div className="fingerprint-spinner">
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
        <div className="spinner-ring" />
      </div>
    </div>
  );
};

export default LoaderElement;
