/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

// check the number of correct answer in the given candidate answer object
const getCorrectAnswerCount = answer => {
  let correctAnswerCount = 0;
  if (answer.single.answer !== null && answer.single.mark) {
    correctAnswerCount = 1;
  }
  if (answer.multi !== null) {
    answer.multi.forEach(val => {
      if (val.mark) {
        correctAnswerCount++;
      }
    });
  }
  return correctAnswerCount;
};

export const getParsedData = candidateDetails => {
  candidateDetails.candidateReportDetails.selectedAnswers.skills.forEach(
    skill => {
      skill.scoredPercentage = Math.round(
        (skill.marksScored / skill.totalMarks) * 100
      );
    }
  );

  if (
    candidateDetails.candidateReportDetails.selectedAnswers.psychometricSkills
  )
    candidateDetails.candidateReportDetails.selectedAnswers.psychometricSkills.forEach(
      skill => {
        skill.scoredPercentage = Math.round(
          (skill.marksScored / skill.totalMarks) * 100
        );
      }
    );
  return candidateDetails;
};
