import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../shared/components/FormElements/Button';
import InputField from '../../../shared/components/FormElements/InputField';
import Logo from '../../../shared/components/UIElements/Logo';

const ResetPasswordConfirmPassword = () => {
  const { getValues } = useFormContext();
  return (
    <div className="can_login_second">
      <div className="can_mobile_logo">
        <Logo />
      </div>
      <div className="can_login_first_top">
        <div className="can_login_heading"> Set new password</div>
      </div>
      <div className="can_login_form">
        <div className="cmn_form_input_container cmn_wi_full">
          <InputField
            id="newPassword"
            name="newPassword"
            type="password"
            label="New password"
            placeholder=""
            validation={{
              required: 'Required',
            }}
          />
        </div>
        <div className="cmn_form_input_container cmn_wi_full">
          <InputField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label="Confirm new password"
            placeholder=""
            validation={{
              required: 'Required',
              validate: value => {
                if (value !== getValues('newPassword')) {
                  return 'Password mismatch';
                }
                return true;
              },
            }}
          />
        </div>
        <div className="can_login_btn">
          <Button type="submit" className="cad_primary_btn submit_reset">
            Set new password
          </Button>
        </div>
      </div>
      <div className="can_return">
        Return to <Link to="/login">login</Link>
      </div>
    </div>
  );
};

export default ResetPasswordConfirmPassword;
