import { createContext } from 'react';

const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  userData: null,
  clientData: null,
  userRole: null,
  isMasAdmin: false,
  isMasContentAd: false,
  setUserDetails: () => {},
  setClientDetails: () => {},
  setIsAdditionalDetailsFilled: () => {},
  login: () => {},
  logout: () => {},
  setMFAToken: () => {},
  isMFAEnable: false,
});
export default AuthContext;
