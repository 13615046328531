import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VisibilityIcon = ({ className }) => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'eye']}
      className={`icon-remove_red_eyevisibility1 ${className}`}
    />
  );
};

export default VisibilityIcon;
