import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloseIcon = props => {
  const { onClick } = props;
  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={['fas', 'xmark']}
      className={`icon-xmark ${props.className}`}
    />
  );
};

export default CloseIcon;
