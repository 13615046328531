import React from 'react';
import InputField from '../../shared/components/FormElements/InputField';
import SelectField from '../../shared/components/FormElements/SelectField';
import Button from '../../shared/components/FormElements/Button';

const AdditionalDetailsFormElements = () => {
  const openReqOpt = [
    { value: '<5', label: '<5' },
    { value: '6-10', label: '6-10' },
    { value: '10+', label: '10+' },
  ];
  const timelineOpt = [
    { value: 'Immediately', label: 'Immediately' },
    { value: '2 weeks', label: '2 weeks' },
    { value: '1 month', label: '1 month' },
  ];
  const positionOpt = [
    { value: 'Recruiter', label: 'Recruiter' },
    { value: 'Hiring Manager', label: 'Hiring Manager' },
    { value: 'HR Team Member', label: 'HR Team Member' },
    { value: 'Owner', label: 'Owner' },
    { value: 'Executive', label: 'Executive' },
    { value: 'Others', label: 'Others' },
  ];
  const testingDomainOpt = [
    { value: 'Analytical Ability', label: 'Analytical Ability' },
    { value: 'Administrative functions', label: 'Administrative functions' },
    { value: 'Programming', label: 'Programming' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Accounting/Finance', label: 'Accounting/Finance' },
    { value: 'HR functions', label: 'HR function' },
    { value: 'Business Management', label: 'Business Management' },
    { value: 'Marketing/SEO', label: 'Marketing/SEO' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Software/Cloud/AI/ML', label: 'Software/Cloud/AI/ML' },
    { value: 'Others', label: 'Others' },
  ];

  return (
    <div className="cmn_form_fields">
      <div className="cmn_form_input_container cmn_wi_full">
        <InputField
          id="country"
          name="country"
          type="text"
          label="Country"
          placeholder=""
          validation={{
            required: 'Required',
          }}
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <InputField
          id="contactNumber"
          name="contactNumber"
          type="text"
          label="Contact Number"
          placeholder=""
          validation={{
            required: 'Required',
          }}
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <SelectField
          id="testingDomain"
          name="testingDomain"
          label="Would like to test for"
          defaultValue=""
          options={testingDomainOpt}
          isMulti
          required
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <SelectField
          id="openPositions"
          name="openPositions"
          label="Number of open positions to be filled"
          defaultValue=""
          options={openReqOpt}
          required
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <SelectField
          id="position"
          name="position"
          label="Are you a"
          defaultValue=""
          options={positionOpt}
          required
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <SelectField
          id="decidingTimeline"
          name="decidingTimeline"
          label="Will decide in"
          defaultValue=""
          options={timelineOpt}
          required
        />
      </div>
      <div className="cmn_form_input_container cmn_wi_full">
        <InputField
          id="hiringDepartment"
          name="hiringDepartment"
          type="text"
          label="Department hiring for"
          placeholder=""
          validation={{
            required: 'Required',
          }}
        />
      </div>
      <Button
        type="submit"
        className="cmn_btn cmn_primary cmn_save_btn addtnl_btn"
      >
        Next
      </Button>
    </div>
  );
};

export default AdditionalDetailsFormElements;
