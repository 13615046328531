import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import CancelIcon from '../UIElements/CancelIcon';
import SnackBarContext from '../../contexts/SnackBarContext';
import './SnackBar.css';

// Snackbar default values
export const defaultPosition = 'top-right';
export const defaultDuration = 5000;
export const defaultInterval = 250;
export const defaultType = 'info';
export const positions = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
];

const Snackbar = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [text, setText] = useState('');
  const [duration, setDuration] = useState(defaultDuration);
  const [position, setPosition] = useState(defaultPosition);
  const [customStyles, setCustomStyles] = useState({});
  const [closeCustomStyles, setCloseCustomStyles] = useState({});
  const [type, setType] = useState(defaultType);

  const triggerSnackbar = (
    sbText,
    sbDuration,
    sbPosition,
    style,
    closeStyle,
    sbType
  ) => {
    setText(sbText);
    setDuration(sbDuration);
    setPosition(sbPosition);
    setCustomStyles(style);
    setCloseCustomStyles(closeStyle);
    setType(sbType);
    setOpen(true);
  };

  const openSnackbar = (
    sbText,
    sbDuration,
    sbPosition,
    style,
    closeStyle,
    sbType
  ) => {
    if (open === true) {
      setOpen(false);
      setTimeout(() => {
        triggerSnackbar(
          sbText,
          sbDuration,
          sbPosition,
          style,
          closeStyle,
          sbType
        );
      }, defaultInterval);
    } else {
      triggerSnackbar(
        sbText,
        sbDuration,
        sbPosition,
        style,
        closeStyle,
        sbType
      );
    }
  };

  const closeSnackbar = () => {
    setOpen(false);
  };
  return (
    <SnackBarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <CSSTransition
        in={open}
        timeout={150}
        mountOnEnter
        unmountOnExit
        // Sets timeout to close the snackbar
        onEnter={() => {
          clearTimeout(timeoutId);
          setTimeoutId(setTimeout(() => setOpen(false), duration));
        }}
        // Sets custom classNames based on "position"
        className={`snackbar-wrapper snackbar-wrapper-${position}`}
        classNames={{
          enter: `snackbar-enter snackbar-enter-${position}`,
          enterActive: `snackbar-enter-active snackbar-enter-active-${position}`,
          exitActive: `snackbar-exit-active snackbar-exit-active-${position}`,
        }}
      >
        <div>
          <div className={`snackbar ${type}`} style={customStyles}>
            <div className="snackbar__text">{text}</div>
            <button
              onClick={closeSnackbar}
              className="snackbar__close"
              style={closeCustomStyles}
            >
              <CancelIcon />
            </button>
          </div>
        </div>
      </CSSTransition>
    </SnackBarContext.Provider>
  );
};
export default Snackbar;
