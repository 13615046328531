import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvisibilityIcon = props => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'eye-slash']}
      className="icon-visibility_off1"
    />
  );
};

export default InvisibilityIcon;
