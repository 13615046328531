import React from 'react';
import PaddedPieChart from '../../shared/components/Charts/PaddedPieChart';
import Stars from '../../shared/components/Stars/Stars';

const CandidateSkillAnalysis = ({
  skill,
  candidate,
  skillChartData,
  skillStarData,
  openPopupToggle,
}) => {
  const correctQuestionsPercentage =
    skill.answeredQuestions > 0
      ? Math.round((skill.correctQuestions / skill.answeredQuestions) * 100)
      : 0;

  return (
    <div className="cmn_form_fields cmn_mgn_top">
      <div className="overall_skill_flex">
        <div className="overall_skill_chart">
          <div className="das_analyst_tab_ovrl_cnd_anlys">
            <div className="das_cnd_details">
              <PaddedPieChart
                innerRadius={60}
                width={150}
                height={150}
                data={skillChartData}
                dataKey="value"
                legend
              />
            </div>
            <span className="star_box">
              <Stars feedbackRating={skillStarData} />
            </span>
          </div>
        </div>
        <div className="overall_skill_desc">
          <p className="cand_answered_text">{skill.skill.description}</p>
          <div className="ov_skil_question_flex">
            <div className="cand_answered_text">
              <p>
                <span className="highlighted_text">
                  {candidate.candidateReportDetails.additionalDetails[0].value}
                </span>
                has attended
                <span className="highlighted_text">
                  {skill.answeredQuestions}
                </span>
                out of
                <span className="highlighted_text">{skill.totalQuestions}</span>
                questions and got
                <span className="highlighted_text">
                  {correctQuestionsPercentage
                    ? `${correctQuestionsPercentage}%`
                    : 'None'}
                </span>
                of the answers right.
              </p>
            </div>
            <div className="view_question">
              <div
                onClick={() =>
                  openPopupToggle({
                    name: skill.skill.skillName,
                    answers: skill.questions,
                  })
                }
              >
                View Questions
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSkillAnalysis;
