import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminLoginForm from '../Admin/Login/AdminLoginForm';
import ContentAdminLogin from '../ContentAdmin/Login/ContentAdminLogin';
import ForgotPwdForm from '../Login/Pages/ForgotPwdForm';
import LoginForm from '../Login/Pages/LoginForm';
import Signup from '../User/Pages/Signup';
import SignupVerification from '../User/Pages/SignupVerification';
import ResetPassword from '../User/Pages/ResetPassword';
import ResetPasswordContentAdmin from '../Admin/ContentAdmin/Pages/ResetPasswordContentAdmin';
import CandidateReport from '../CandidateSummary/Pages/CandidateReport';

const LoginRoute = () => (
  <Routes>
    <Route path="/login" element={<LoginForm />} />
    <Route path="/user/verification/:token" element={<SignupVerification />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/resetpassword" element={<ResetPassword />} />
    <Route path="/newpassword/:token" element={<ResetPassword />} />
    <Route
      path="/content-admin/newpassword/:token"
      element={<ResetPasswordContentAdmin />}
    />
    <Route path="/forgotpassword" element={<ForgotPwdForm />} />
    <Route path="/admin/login" element={<AdminLoginForm />} />
    <Route path="/content-admin/login" element={<ContentAdminLogin />} />
    <Route
      path="/candidate/report/:tid/:cid/:reportToken"
      element={<CandidateReport />}
    />
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
);

export default LoginRoute;
