import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ResetPassword.css';
import undraw_safe_bnk7 from '../../../shared/images/undraw_safe_bnk7.png';
import undraw_security_o890 from '../../../shared/images/undraw_security_o890.png';
import Logo from '../../../shared/components/UIElements/Logo';
import ResetPasswordConfirmPassword from '../Components/ResetPasswordConfirmPassword';
import Form from '../../../shared/components/FormElements/Form';
import useContentAdminAPI from '../../../shared/hooks/useContentAdminApi';
import useSnackbar from '../../../shared/hooks/SnackBarHook';

const ResetPasswordContentAdmin = () => {
  const { token } = useParams();
  const [adminId, setAdminId] = useState(null);
  const { resetPassword, validateResetPasswordToken, error } =
    useContentAdminAPI();
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    if (error !== undefined && error.length > 0) openSnackbar(error, 'error');
  }, [error]);

  const onSubmit = data => {
    if (token) {
      const setNewPassword = async () => {
        try {
          await resetPassword(adminId, token, data.newPassword);
          openSnackbar('Password updated successfully.', 'success');
          window.location.href = '/content-admin/login';
        } catch (err) {
          console.error(err);
        }
      };
      if (adminId) setNewPassword();
    }
  };

  useEffect(() => {
    if (token) {
      const validate = async () => {
        try {
          const adminDataId = await validateResetPasswordToken(token);
          if (adminDataId && adminDataId.adminId) {
            setAdminId(adminDataId.adminId);
          }
        } catch (err) {
          console.error(err);
        }
      };
      validate();
    }
  }, []);

  return (
    <div className="can_login_container">
      <div className="can_login_body">
        <div className="can_login_first">
          <div className="reset_pass logo_container">
            <Logo />
          </div>
          <div className="can_login_main_img">
            <img
              src={token ? undraw_safe_bnk7 : undraw_security_o890}
              alt="reset password page"
            />
          </div>
        </div>
        <Form onSubmit={onSubmit}>
          <ResetPasswordConfirmPassword />
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordContentAdmin;
