import React, { useState } from 'react';
import './Loader.css';
import LoaderContext from '../../contexts/LoaderContext';
import LoaderElement from './LoaderElement';

const Loader = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const showLoader = loaderStatus => {
    setIsVisible(loaderStatus);
  };
  return (
    <>
      <LoaderContext.Provider value={{ showLoader, isVisible }}>
        {children}
        {isVisible && <LoaderElement />}
      </LoaderContext.Provider>
    </>
  );
};

export default Loader;
