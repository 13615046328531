import React from 'react';

const TraitScoreProgressBar = ({ scorePercent }) => (
  <div className="summary_progress_bar">
    <div
      className="summary_correctAnswer"
      style={{ width: `${scorePercent}%` }}
    />
    <div className="max_skill_mark">100%</div>
    <div className="begin_skill_mark">0%</div>
  </div>
);

export default TraitScoreProgressBar;
