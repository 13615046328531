import React from 'react';
import Button from '../FormElements/Button';
import PrevIcon from '../UIElements/PrevIcon';
import NextIcon from '../UIElements/NextIcon';

const Carousel = ({ images }) => {
  let index = 0;
  let noOfSlides = document.getElementsByClassName('slides');

  const showSlide = x => {
    if (x > noOfSlides.length - 1) {
      index = 0;
    }
    if (x < 0) {
      index = noOfSlides.length - 1;
    }
    for (let i = 0; i < noOfSlides.length - 1; i++) {
      noOfSlides[i].style.display = 'none';
    }
    noOfSlides[index].style.display = 'block';
  };

  const nextSlide = () => {
    index += 1;
    showSlide(index);
  };

  const prevSlide = () => {
    index -= 1;
    showSlide(index);
  };

  return (
    <>
      {images.length > 0 ? (
        <div id="carousel_container">
          <div id="slide-container">
            {images.map((slide, index) => {
              return (
                <div className="slides" key={index}>
                  <img className="slide" src={slide} alt="slide" />
                </div>
              );
            })}
            <Button id="next" className="arrow" onClick={() => nextSlide()}>
              <NextIcon />
            </Button>
            <Button id="previous" className="arrow" onClick={() => prevSlide()}>
              <PrevIcon />
            </Button>
          </div>
        </div>
      ) : (
        <div className="no_image_cont">
          <p>No Images</p>
        </div>
      )}
    </>
  );
};

export default Carousel;
